import { useCallback, useEffect, useRef, useState } from "react";
import Map, {
  Layer,
  Marker,
  Popup,
  Source
} from "react-map-gl/maplibre";
import { LngLatBounds } from "maplibre-gl";
import { debounce } from 'lodash';
import { Tooltip } from "react-tooltip";
import {
  DEFAULT_MAP_THEME,
  LOCATION_AREA,
  LOCATION_BLOCK,
  LOCATION_COMPARE,
  LOCATION_PROPERTY,
  LOCATION_SCHOOL,
  LOCATION_STATION,
  MARKET_SEGMENT_ID_MAP,
  getMapStyleFromTheme,
  getTenureValue,
  isRegion,
  transformMapboxStyleRequest
} from "@/utils/areas";
import RadiusOverlay from "./map/RadiusOverlay";
import { DEBOUNCE_TIMING, searchLatLng } from "@/utils/api";
import { checkMediaQuery, noSelectClass } from "@/utils/user";
import AreaMarker from "./map/AreaMarker";
import MapButtons from "./map/MapButtons";
import PropertyMarker from "./map/PropertyMarker";
import {
  MAP_FILTER_OPTIONS,
  MODE_DEFAULT_MAP,
  MODE_UPCOMING,
  generateLinesData,
  groupPropertyByArea
} from "@/utils/map";
import UpcomingMarker from "./map/UpcomingMarker";
import {
  FIELD_AREAS,
  FIELD_DISTRICTS,
  FIELD_MAX_SIZE,
  FIELD_MAX_UNIT_PRICE,
  FIELD_MIN_SIZE,
  FIELD_MIN_UNIT_PRICE,
  FIELD_REGIONS,
  FILTER_AVG_PRICE,
  FILTER_AVG_UNIT_PRICE,
  FILTER_COMPLETION_DATE,
  FILTER_HDB_BUYERS,
  FILTER_LAUNCH_PERC,
  FILTER_LOCATION,
  FILTER_MAP_TRANSACTION_DATE,
  FILTER_PROFITABLE,
  FILTER_PROPERTY_AGE,
  FILTER_PROPERTY_TYPE,
  FILTER_PSF_RENTAL_6M,
  FILTER_SIZE,
  FILTER_TENURE,
  FILTER_TOTAL_UNITS,
  hasAnyValue,
  hasFilterSelected,
  hasMaxRangeSelected,
  hasMinRangeSelected,
  hasValidInput,
  initializeFilters
} from "@/utils/filter";
import Sidebar from "@/components/common/Sidebar";
import { convertMapUrl } from "@/utils/url";
import { calculateAgeYear, calculateDist, deepCopy, getPropTypeFilterSelected } from "@/utils/convert";
import StationMarker from "./map/StationMarker";
import SchoolMarker from "./map/SchoolMarker";
import BlockMarker from "./map/BlockMarker";

const DEFAULT_PROPERTY_ZOOM = 16;
const DEFAULT_AREA_ZOOM = 14;
const DEFAULT_FULL_ZOOM = 10;

const MARKER_SCHEME_OPTIONS = [
  {
    id: 'profitable',
    label: 'Profitable %'
  },
  // {
  //   id: 'avgPsf3m',
  //   label: 'Last 3 months PSF'
  // }
];

const MainMap = ({
  user,
  target,
  focus,
  setFocus,
  mapMediaStyle,
  mapData,
  upcomingData,
  goToProperty,
  goToUpcomingProperty,
  goToArea,
  goToStation,
  goToSchool,
  isMinimized,
  isMaximized,
  mode,
  setMode,
  setLoading,
  setErr,
  closePropertyDetails,
  loadAdvMapData,
  hasAdvMapData,
  filterSelected,
  setFilterSelected,
  propIndex,
  compareList,
  viewComparePro
  // mapGridData
}) => {
  const mapRef = useRef();

  const [allowInteraction, setAllowInteraction] = useState(true);
  const [showZoomHint, setShowZoomHint] = useState(true);

  const [mapStyle, setMapStyle] = useState(DEFAULT_MAP_THEME);
  const [zoom, setZoom] = useState(DEFAULT_FULL_ZOOM);

  const [sideContent, setSideContent] = useState(null);

  const [placeMarkers, setPlaceMarkers] = useState([]);

  const [markerScheme, setMarkerScheme] = useState(MARKER_SCHEME_OPTIONS[0].id);
  
  const filterProperties = (props, filterSelected) => props.filter(p => {
    const areaFilter = new Set(Object.keys(filterSelected[FIELD_AREAS])
      .filter(area => filterSelected[FIELD_AREAS][area] && !isRegion(area)));

    const districtFilter = new Set(Object.keys(filterSelected[FIELD_DISTRICTS])
        .filter(district => filterSelected[FIELD_DISTRICTS][district]));

    const regionFilter = new Set(Object.keys(filterSelected[FIELD_REGIONS])
        .filter(region => filterSelected[FIELD_REGIONS][region])
        .map(region => MARKET_SEGMENT_ID_MAP[region]))

    if (hasFilterSelected(FILTER_PROPERTY_AGE, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_PROPERTY_AGE, filterSelected[FILTER_PROPERTY_AGE.field]);
      const age = calculateAgeYear(p.tenureDate, p.completion);
      if (hasMin) {
        const minAge = filterSelected[FILTER_PROPERTY_AGE.field].value.min;
        if (age === null || age < minAge) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_PROPERTY_AGE, filterSelected[FILTER_PROPERTY_AGE.field]);
      if (hasMax) {
        const maxAge = filterSelected[FILTER_PROPERTY_AGE.field].value.max;
        if (age > maxAge) return false;
      }
    }

    if (hasFilterSelected(FILTER_AVG_PRICE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_PRICE]);
      if (hasMin) {
        const minPrice = filterSelected[FIELD_MIN_PRICE];
        if (p.minPrice === null || p.minPrice < minPrice) return false;
      }
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_PRICE]);
      if (hasMax) {
        const maxPrice = filterSelected[FIELD_MAX_PRICE];
        if (p.maxPrice === null || p.maxPrice > maxPrice) return false;
      }
    }

    if (hasFilterSelected(FILTER_AVG_UNIT_PRICE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_UNIT_PRICE]);
      if (hasMin) {
        const minPsf = filterSelected[FIELD_MIN_UNIT_PRICE];
        if (p.avgPsf3m === null || p.avgPsf3m < minPsf) return false;
      }
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_UNIT_PRICE]);
      if (hasMax) {
        const maxPsf = filterSelected[FIELD_MAX_UNIT_PRICE];
        if (p.avgPsf3m === null || p.avgPsf3m > maxPsf) return false;
      }
    }

    if (hasFilterSelected(FILTER_PROFITABLE, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_PROFITABLE, filterSelected[FILTER_PROFITABLE.field]);
      if (hasMin) {
        const minProfit = filterSelected[FILTER_PROFITABLE.field].value.min;
        if (p.profitable < 0 || p.profitable === null || p.profitable < minProfit) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_PROFITABLE, filterSelected[FILTER_PROFITABLE.field]);
      if (hasMax) {
        const maxProfit = filterSelected[FILTER_PROFITABLE.field].value.max;
        if (p.profitable < 0 || p.profitable === null || p.profitable > maxProfit) return false;
      }
    }

    if (hasFilterSelected(FILTER_COMPLETION_DATE, filterSelected)) {
      const completedYear = !!p.completion ? parseInt(p.completion) : null;
      const minDate = filterSelected[FILTER_COMPLETION_DATE.field[0]];
      if (minDate !== null) {
        const startYear = minDate.getFullYear();
        if (!!startYear && completedYear < startYear) return false;
      }
      const maxDate = filterSelected[FILTER_COMPLETION_DATE.field[1]];
      if (maxDate !== null) {
        const endYear = maxDate.getFullYear();
        if (!!endYear && (!completedYear || completedYear > endYear)) return false;
      }
    }

    if (hasFilterSelected(FILTER_PROPERTY_TYPE, filterSelected)) {
      const propFilter = getPropTypeFilterSelected(filterSelected, FILTER_PROPERTY_TYPE);
      const types = new Set(p.types);
      if (!propFilter.some(t => types.has(t))) return false;
    }

    if (hasFilterSelected(FILTER_TENURE, filterSelected)) {
      const tenureFilter = Object.keys(filterSelected[FILTER_TENURE.field])
        .filter(o => filterSelected[FILTER_TENURE.field][o])
        .map(o => getTenureValue(o));
        const types = new Set(p.tenures).map(t => t.includes(' yrs ') ? parseInt(t.split(' yrs ')[0]).toString() : t);
        if (!tenureFilter(t => types.has(t))) return false;
    }

    if (hasFilterSelected(FILTER_SIZE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_SIZE]);
      if (hasMin) {
        const minSize = filterSelected[FIELD_MIN_SIZE];
        if (p.minSize < minSize) return false;
      }
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_SIZE]);
      if (hasMax) {
        const maxSize = filterSelected[FIELD_MAX_SIZE];
        if (p.maxSize > maxSize) return false;
      }
    }

    if (hasFilterSelected(FILTER_LOCATION, filterSelected)) {
      if (hasAnyValue(filterSelected[FIELD_AREAS])) {
        if (!areaFilter.has(p.area)) return false;
      } else if (hasAnyValue(filterSelected[FIELD_DISTRICTS])) {
        if (!districtFilter.has(p.district)) return false;
      } else if (hasAnyValue(filterSelected[FIELD_REGIONS])) {
        if (!regionFilter.has(p.marketSegment)) return false;
      }
    }

    if (hasFilterSelected(FILTER_MAP_TRANSACTION_DATE, filterSelected)) {
      const minDate = filterSelected[FILTER_MAP_TRANSACTION_DATE.field[0]];
      if (minDate !== null) {
        if (p.lastTxDate < minDate) return false;
      }
      const maxDate = filterSelected[FILTER_MAP_TRANSACTION_DATE.field[1]];
      if (maxDate !== null) {
        if (p.lastTxDate > maxDate) return false;
      }
    }

    if (hasFilterSelected(FILTER_HDB_BUYERS, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_HDB_BUYERS, filterSelected[FILTER_HDB_BUYERS.field]);
      if (hasMin) {
        const minVal = filterSelected[FILTER_HDB_BUYERS.field].value.min;
        if (p.hdbBuyers < minVal) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_HDB_BUYERS, filterSelected[FILTER_HDB_BUYERS.field]);
      if (hasMax) {
        const maxVal = filterSelected[FILTER_HDB_BUYERS.field].value.max;
        if (p.hdbBuyers > maxVal) return false;
      }
    }

    if (hasFilterSelected(FILTER_TOTAL_UNITS, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_TOTAL_UNITS, filterSelected[FILTER_TOTAL_UNITS.field]);
      if (hasMin) {
        const minVal = filterSelected[FILTER_TOTAL_UNITS.field].value.min;
        if (p.totalUnits < minVal) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_TOTAL_UNITS, filterSelected[FILTER_TOTAL_UNITS.field]);
      if (hasMax) {
        const maxVal = filterSelected[FILTER_TOTAL_UNITS.field].value.max;
        if (p.totalUnits > maxVal) return false;
      }
    }

    if (hasFilterSelected(FILTER_LAUNCH_PERC, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_LAUNCH_PERC, filterSelected[FILTER_LAUNCH_PERC.field]);
      if (hasMin) {
        const minVal = filterSelected[FILTER_LAUNCH_PERC.field].value.min;
        if (p.soldAtLaunch < minVal) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_LAUNCH_PERC, filterSelected[FILTER_LAUNCH_PERC.field]);
      if (hasMax) {      
        const maxVal = filterSelected[FILTER_LAUNCH_PERC.field].value.max;
        if (p.soldAtLaunch > maxVal) return false;
      }
    }

    if (hasFilterSelected(FILTER_PSF_RENTAL_6M, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_PSF_RENTAL_6M, filterSelected[FILTER_PSF_RENTAL_6M.field]);
      if (hasMin) {
        const minVal = filterSelected[FILTER_PSF_RENTAL_6M.field].value.min;
        if (p.last6mAvgRentPsf < minVal) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_PSF_RENTAL_6M, filterSelected[FILTER_PSF_RENTAL_6M.field]);
      if (hasMax) {
      const maxVal = filterSelected[FILTER_PSF_RENTAL_6M.field].value.max;
        if (p.last6mAvgRentPsf > maxVal) return false;
      }
    }
    
    return true;
  });

  const [filteredMapData, setFilteredMapData] = useState(mapData);
  const [filteredMapCheck, setFilteredMapCheck] = useState(null);
  const [filteredMapAreas, setFilteredMapAreas] = useState(groupPropertyByArea(filteredMapData));

  useEffect(() => {
    if (focus && focus.target && focus.target.lat && focus.target.lng) {
      if (focus.links && focus.links.length > 0) {
        setTimeout(() => {
          const coords = [focus.target, ...focus.links];
          const bounds = new LngLatBounds();
          coords.forEach(c => bounds.extend([c.lng, c.lat]));
          mapRef.current.fitBounds(bounds, { padding: checkMediaQuery() ? 88 : 188 });
          setTimeout(() => {
            const currentZoom = mapRef.current.getZoom();
            if (currentZoom <= DEFAULT_AREA_ZOOM)
              flyTo([focus.target.lng, focus.target.lat], DEFAULT_AREA_ZOOM + 0.1);
          }, 688);
        }, 188);
      } else {
        setTimeout(() => {
          if (focus.type === LOCATION_AREA) {
            flyTo([focus.target.lng, focus.target.lat], zoom);
          } else {
            flyTo([focus.target.lng, focus.target.lat]);
          }
        }, 188);
      }
    }
  }, [focus]);

  useEffect(() => {
    setFocus(null);
    onMapMoveEnd();
  }, [mode]);

  /* Handle map interaction */
  const onMapMoveEnd = () => {
    if ((mode === MODE_DEFAULT_MAP || (mode === MODE_UPCOMING && target)) && mapRef.current) {
      const currZoom = mapRef.current.getZoom();
      if (currZoom != zoom) setZoom(currZoom);
      if (currZoom > DEFAULT_AREA_ZOOM) {
        const bounds = mapRef.current.getBounds();
        const center = mapRef.current.getCenter();
        searchLatLng([
          bounds.getNorthEast().lat,
          bounds.getNorthEast().lng,
          bounds.getSouthEast().lat,
          bounds.getSouthEast().lng,
          bounds.getSouthWest().lat,
          bounds.getSouthWest().lng,
          bounds.getNorthWest().lat,
          bounds.getNorthWest().lng,
        ], [center.lat, center.lng], (data) => {
          setPlaceMarkers(data);
        }, () => {
          // do nothing for now
        });

        // setPlaceMarkers(getPropsInMapGrids(mapGridData, [
        //   bounds.getNorthEast().lat,
        //   bounds.getNorthEast().lng,
        //   bounds.getSouthEast().lat,
        //   bounds.getSouthEast().lng,
        //   bounds.getSouthWest().lat,
        //   bounds.getSouthWest().lng,
        //   bounds.getNorthWest().lat,
        //   bounds.getNorthWest().lng,
        // ]));
      }
    }
    setAllowInteraction(true);
  };

  const onDebouncedZoom = useCallback(debounce(() => {
    const zoomLevel = mapRef.current.getZoom();
    setZoom(zoomLevel);
  }, DEBOUNCE_TIMING), []);

  const onMapZoom = () => {
    if (allowInteraction) {
      onDebouncedZoom();
    }
  };

  const flyTo = (coordinates, zoom = DEFAULT_PROPERTY_ZOOM) => {
    const map = mapRef.current?.getMap();
    if (!map) return;
    setAllowInteraction(false);

    map.flyTo({
      center: coordinates,
      essential: true,
      zoom: zoom,
      transitionDuration: 388
    });
  };

  const closeSideBar = () => {
    if (document.getElementById('mapSidebar').classList.contains('show')) {
      document.getElementById('mapside-close-button').click();
    }
  };

  const applyData = (data) => {
    const newMapData = filterProperties(data, filterSelected);
    const newMapAreas = groupPropertyByArea(newMapData);
    setFilteredMapData(newMapData);
    setFilteredMapCheck(newMapData.reduce((s, p) => s.add(p.marker), new Set()));
    setFilteredMapAreas(newMapAreas);
  };

  const onApplyFilter = () => {
    const requireAdvData = hasFilterSelected(FILTER_PROPERTY_AGE, filterSelected)
      || hasFilterSelected(FILTER_AVG_PRICE, filterSelected)
      || hasFilterSelected(FILTER_SIZE, filterSelected)
      || hasFilterSelected(FILTER_MAP_TRANSACTION_DATE, filterSelected)
      || hasFilterSelected(FILTER_HDB_BUYERS, filterSelected)
      || hasFilterSelected(FILTER_TOTAL_UNITS, filterSelected)
      || hasFilterSelected(FILTER_LAUNCH_PERC, filterSelected)
      || hasFilterSelected(FILTER_PSF_RENTAL_6M, filterSelected);
    if (requireAdvData && !hasAdvMapData) {
      loadAdvMapData((newData) => {
        applyData(newData);
      });
    } else {
      applyData(mapData);
    }
    closeSideBar();
  };

  const onResetFilter = () => {
    const newFilter = initializeFilters(MAP_FILTER_OPTIONS, new URLSearchParams());
    const newMapData = deepCopy(mapData);
    const newMapAreas = groupPropertyByArea(newMapData);
    setFilterSelected(newFilter);
    setFilteredMapData(newMapData);
    setFilteredMapCheck(null);
    setFilteredMapAreas(newMapAreas);
    convertMapUrl(null);
  };

  return (
    <>
      {/* for disabling map interaction while moving */}
      {!allowInteraction && (
        <div
          style={{
            ...mapMediaStyle,
            position: 'absolute',
            top: '132px',
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            zIndex: 10,
            overflow: 'hidden'
          }}
        />
      )}

      {/* map */}
      <Map
        ref={mapRef}
        maxBounds={[103.3, 1.0, 104.3, 1.7]}
        mapStyle={getMapStyleFromTheme(mapStyle)}
        style={mapMediaStyle}
        initialViewState={{
          longitude: 103.831833,
          latitude: 1.304833,
          zoom: DEFAULT_FULL_ZOOM
        }}
        attributionControl={false}
        transformRequest={transformMapboxStyleRequest}
        onMoveEnd={onMapMoveEnd}
        onZoom={onMapZoom}
      >
        {/* radius circle overlay */}
        {focus?.target && focus?.type !== LOCATION_AREA && zoom > DEFAULT_AREA_ZOOM
          && <>
            <RadiusOverlay
              target={focus.target}
              radiusKm={1}
            />
            <RadiusOverlay
              target={focus.target}
              radiusKm={2}
            />
          </>
        }

        {/* marker paths overlay */}

        {/* new and upcoming markers */}
        {mode === MODE_UPCOMING && upcomingData?.map((p, i) =>
          <UpcomingMarker
            idx={i}
            property={p}
            goToProperty={goToUpcomingProperty}
          />
        )}

        {/* property markers */}
        {(mode === MODE_DEFAULT_MAP || (mode === MODE_UPCOMING && target))
          && zoom > DEFAULT_AREA_ZOOM
          && <>
            {placeMarkers.filter(p => p.type === LOCATION_PROPERTY)
              .filter(p => !filteredMapCheck || filteredMapCheck.has(p.marker))
              .map((p, i) => 
                <PropertyMarker
                  idx={i}
                  target={target}
                  property={p}
                  goToProperty={goToProperty}
                  data={mapData[propIndex[p.marker]]}
                  markerScheme={markerScheme}
                />
              )
            }
            {placeMarkers.filter(p => p.type === LOCATION_STATION)
              .map((p, i) =>
                <StationMarker
                  idx={i}
                  station={p}
                  onClick={() => goToStation(p)}
                />
              )
            }
            {placeMarkers.filter(p => p.type === LOCATION_SCHOOL)
              .map((p, i) => 
                <SchoolMarker
                  idx={i}
                  school={p}
                  onClick={() => goToSchool(p)}
                />
              )
            }
          </>
        }

        {/* area markers */}
        {mode === MODE_DEFAULT_MAP && zoom <= DEFAULT_AREA_ZOOM && filteredMapAreas.map((a, i) =>
          <AreaMarker
            idx={i}
            area={a}
            onClick={() => goToArea(a)}
            markerScheme={markerScheme}
          />
        )}

        {/* block markers */}
        {focus?.target?.type === LOCATION_BLOCK
          && <BlockMarker
            idx={888}
            target={focus?.target}
          />
        }
        {focus?.links?.length > 0 && focus?.links?.some(l => l.type === LOCATION_BLOCK)
          && focus.links.map((l, i) =>
            <BlockMarker
              idx={i}
              target={l}
            />
          )
        }

        {/* lines */}
        {focus?.links?.length > 0
          && <Source id="highlight-path-block-prop" type="geojson" data={generateLinesData(focus.target, focus.links)}>
            <Layer {...{
              id: 'route',
              type: 'line',
              paint: {
                'line-color': '#0000ff',
                'line-width': 2,
                'line-dasharray': [2, 2],
              }
            }} />
          </Source>
        }

      </Map>

      {/* map buttons */}
      <div className="proj-map-bars noselect px-5" style={{ width: mapMediaStyle.width }}>
        <MapButtons
          target={target}
          theme={mapStyle}
          setTheme={setMapStyle}
          mode={mode}
          setMode={setMode}
          onSelectFilter={(content) => setSideContent(content)}
          markerSchemeOptions={MARKER_SCHEME_OPTIONS}
          markerScheme={markerScheme}
          setMarkerScheme={setMarkerScheme}
        />
        
        {showZoomHint && mode === MODE_DEFAULT_MAP && zoom <= DEFAULT_AREA_ZOOM
          && <div className="container container-map-hint">
            <div className="alert alert-info text-14 text-center py-5 d-flex" role="alert">
              <span className="p-2 flex-grow-1">Zoom in to see properties instead of area clusters</span>
              <button
                className="p-2 button mr-10 text-blue-1 text-12"
                onClick={() => {
                  const map = mapRef.current?.getMap();
                  if (map) flyTo(map.getCenter(), DEFAULT_AREA_ZOOM + 0.1);
                }}
              >ZOOM IN</button>
              <button
                className="p-2 button ml-10 text-blue-1 text-12"
                onClick={() => setShowZoomHint(false)}
              >
                <i className="icon-close" />
              </button>
            </div>
          </div>
        }
      </div>

      {!isMaximized
        && <div
          className="proj-map-set noselect d-flex justify-content-center"
          style={{
            width: mapMediaStyle?.width,
            ...(checkMediaQuery() ? { bottom: (target && !isMinimized) ? '49svh' : '66px' } : {})
          }}
        >
          {Object.keys(compareList).length > 0 && target?.type !== LOCATION_COMPARE
            && <button
              className="p-2 button -sm -dark-1 bg-white text-blue-1 border-light rounded-100 text-12 px-10 py-5 mr-5"
              onClick={() => viewComparePro()}
            >
              <i className="icon-award text-15 mr-5" />
              Compare PRO
            </button>
          }
          {focus && focus.target?.id !== target?.id
            && <button
              className="p-2 button -sm -dark-1 bg-white text-blue-1 border-light rounded-100 text-12 px-10 py-5 mr-5"
              onClick={() => setFocus(null)}
            >
              Clear Selection
              <i className="icon-close ml-5 text-10" />
            </button>
          }
          {target && target.type === LOCATION_PROPERTY && (
              mapRef.current?.getCenter().lat?.toFixed(6) !== mapData[propIndex[target.id]].lat?.toFixed(6)
              || mapRef.current?.getCenter().lng?.toFixed(6) !== mapData[propIndex[target.id]].lng?.toFixed(6)
            )
            && <button
              className="p-2 button -sm -dark-1 bg-white text-blue-1 border-light rounded-100 text-12 px-10 py-5"
              onClick={() => {
                const d = mapData.find(d => d.name === target.id);
                flyTo([d.lng, d.lat], DEFAULT_PROPERTY_ZOOM);
              }}
            >
              <i className="icon-location text-15 mr-5" />
              Re-center
            </button>
          }
        </div>
      }

      {/* overall map tooltip */}
      <Tooltip id="map-tooltip" style={{ zIndex: 900 }} />
      <Tooltip id="prop-tooltip" style={{ zIndex: 900 }} />
      <Tooltip id="amenity-tooltip" style={{ zIndex: 900 }} />

      {/* filter and info side panel */}
      <div
        className={`offcanvas offcanvas-start filter-bar-nopad ${noSelectClass(user)}`}
        tabIndex="-1"
        id="mapSidebar"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasLabel">
            {sideContent?.type === 'filter' ? 'Filter Properties' : ''}
          </h5>
          <button
            id="mapside-close-button"
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        {/* Filter */}
        {sideContent?.type === 'filter'
          && <>
            <div className="offcanvas-body">
              <aside className="sidebar y-gap-40 xl:d-block">
                <Sidebar
                  options={MAP_FILTER_OPTIONS}
                  filters={filterSelected}
                  setFilters={setFilterSelected}
                />
              </aside>
            </div>

            <div className="row ml-10 mr-10 mt-10 mb-10">
              <div className="col-3">
                <button
                  className="button -dark-1 py-15 px-40 h-50 col-12 rounded-0 bg-red-1 text-white w-100"
                  onClick={onResetFilter}
                >
                  Reset
                </button>
              </div>
              <div className="col-9">
                <button
                  className="button -dark-1 py-15 px-40 h-full col-12 rounded-0 bg-blue-1 text-white w-100"
                  onClick={onApplyFilter}
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </>
        }

        {/* Compare */}

      </div>
    </>
  );
};

export default MainMap;
