import { checkMediaQuery, noSelectClass } from "@/utils/user";
import ScrollToTop from "../project/ScrollToTop";

const MarkerView = ({
  user,
  err,
  target,
  isMinimized,
  isMaximized,
  handleMaximize,
  handleExpand,
  closePropertyDetails,
  goToProperty
}) => {
  const mediaMatches = checkMediaQuery();
  return (
    <>
      {!isMinimized && err &&
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-between text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
              <div
                className="p-2 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close py-5" />
              </div>
            </div>
          }

          {!mediaMatches
            && <div className="d-flex justify-content-end text-10 text-light-1">
              <div
                className="p-2 px-10 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close" />
              </div>
            </div>
          }

          <div className="modal-body watermark d-flex">
            {!mediaMatches
              && <div
                className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                  <i className="icon-chevron-sm-down px-5" />
                </div>
              </div>
            }

            <div
              id="project-details-modal"
              className={`p-2 modal-scoll-content text-center fw-500 py-40 ${
                isMaximized ? 'modal-scroll-media-full' : (mediaMatches ? 'modal-scroll-media' : '')
              }`}
            >
              Property not found
            </div>
          </div>
        </div>
      }

      {!isMinimized && !err && (
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-between text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
              <div
                className="p-2 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close py-5" />
              </div>
            </div>
          }

          {!mediaMatches
            && <div className="d-flex justify-content-end text-10 text-light-1">
              <div
                className="p-2 px-10 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close" />
              </div>
            </div>
          }

          <div className="modal-body watermark d-flex">
            {!mediaMatches
              && <div
                className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                  <i className="icon-chevron-sm-down px-5" />
                </div>
              </div>
            }

            <div
              id="project-details-modal"
              className={`p-2 modal-upcoming ${
                isMaximized ? 'modal-upcoming-media-full' : (mediaMatches ? 'modal-upcoming-media' : '')
              }`}
            >

              <ScrollToTop />

              <section className={`modal-table${isMaximized ? '-full' : ''} mb-50`}>
                <div className={`data-table ${noSelectClass(user)}`}>
                  <div className="d-flex noselect">
                    <h3 className="p-2 text-15 fw-500">Select a project</h3>
                  </div>
                </div>

                <ul className="px-10 py-10 sm:px-15 sm:py-15">
                  {target.data.projects.sort((a, b) => a.project.localeCompare(b.project)).map(p => (
                    <li
                      className="button -dark-1 bg-blue-1 text-white px-20 py-5 cursor-pointer mb-10"
                      onClick={() => goToProperty(target.id, p.projectId)}
                    >
                      <div className="py-10 px-15 sm:px-5 sm:py-5 text-center">
                        <div className="text-15 lh-15 fw-500">
                          {p.project}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </section>

            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MarkerView;
