import { useState } from 'react';

import CustomTable from '@/components/transactions/CustomTable';
import { STANDARD_PAGE_CONFIG } from '@/utils/table';
import { NA, formatDistanceLabel } from '@/utils/convert';
import { getProfitableTextClass } from '@/utils/map';


const UpcomingCompTable = ({
  property,
  data,
  goToProperty
}) => {
  const DEFAULT_COLUMNS = [
    {
      Header: 'Project',
      accessor: 'name',
      disableGlobalFilter: true,
      Cell: (row) =>
        row.cell.value === property.id
          ? <div className="fw-500">
              {row.cell.value}
            </div>
          : <a
              className="text-blue-1 cursor-pointer"
              onClick={() => goToProperty(row.cell.value)}
            >
              {row.cell.value}
            </a>
    },
    {
      Header: 'Street',
      accessor: 'street',
      disableGlobalFilter: true,
      disableSortBy: true,
    },
    {
      Header: 'Distance',
      accessor: 'distance',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div>
        {formatDistanceLabel(row.cell.value ?? 0, true)}
      </div>
    },
    {
      Header: 'Profitable (%)',
      accessor: 'profitable',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div className={getProfitableTextClass(row.cell.value)}>
        {row.cell.value === null || row.cell.value < 0 ? NA : `${row.cell.value.toFixed(1)}%`}
      </div>
    },
    {
      Header: 'Avg PSF (Last 3mths)',
      accessor: 'last3mAvgPsf',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div className="text-dark">
        {row.cell.value === null || row.cell.value < 0 ? NA : `$${row.cell.value.toFixed(2)}`}
      </div>
    }
  ];

  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  return (
    <CustomTable
      name="upcomp"
      data={
        data.sort((a, b) => a.distance - b.distance)
      }
      tableConfig={STANDARD_PAGE_CONFIG}
      defaultColumns={DEFAULT_COLUMNS}
      fullColumns={fullColumns}
      setFullColumns={setFullColumns}
      columns={columns}
      setColumns={setColumns}
      hideSearchInput
      hidePageButton
    />
  );
};

export default UpcomingCompTable;
