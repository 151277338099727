import { downloadFileUrl } from "@/utils/api";
import {
  decompressGroupCompProfitData,
  decompressGroupCompTxData,
  getPropertyFileName
} from "@/utils/map";
import { useEffect, useState } from "react";
import CompareView from "./CompareView";

const CompareTab = ({
  user,
  data,
  screenDim,
  isMaximized,
  goToProperty,
  setFocusOn,
  compareList,
  setCompareList,
  viewComparePro
}) => {
  const [loading, setLoading] = useState(true);

  const [compData, setCompData] = useState({ p: {}, t: {} });

  const loadGroupData = () => {
    setLoading(true);
    downloadFileUrl('data/comp', getPropertyFileName(data.name) + '_c', null, (url) => {
      fetch(url)
      .then(resp => resp.json())
      .then(json => {
        const txData = {};
        const profitData = {};
        Object.keys(json.t).forEach(p => txData[p] = decompressGroupCompTxData(json.t[p]));
        Object.keys(json.p).forEach(p => profitData[p] = decompressGroupCompProfitData(json.p[p]));
        setCompData({
          t: txData,
          p: profitData
        });
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      })
    }, (err) => {
      setLoading(false);
    });
  };

  useEffect(() => {
    loadGroupData();
  }, []);

  return (
    <CompareView
      user={user}
      data={data}
      loading={loading}
      setLoading={setLoading}
      compData={compData}
      setCompData={setCompData}
      screenDim={screenDim}
      isMaximized={isMaximized}
      goToProperty={goToProperty}
      setFocusOn={setFocusOn}
      compareList={compareList}
      setCompareList={setCompareList}
      viewComparePro={viewComparePro}
    />
  );
};

export default CompareTab;
