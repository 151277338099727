import { MAP_THEMES } from "@/utils/areas";
import { MODE_DEFAULT_MAP, MODE_UPCOMING } from "@/utils/map";
import { checkMediaQuery } from "@/utils/user";

// const MAP_MODES = [
//   MODE_DEFAULT_MAP,
//   MODE_UPCOMING
// ];

const MapButtons = ({
  target,
  theme,
  setTheme,
  mode,
  setMode,
  onSelectFilter,
  markerSchemeOptions,
  markerScheme,
  setMarkerScheme
}) => {
  // const getMapModeLabelShort = (mode) => {
  //   if (mode === MODE_DEFAULT_MAP) return 'Property Search';
  //   if (mode === MODE_UPCOMING) return 'New & Upcoming';
  //   return 'Unknown';
  // };

  // const getMapModeHint = (mode) => {
  //   if (mode === MODE_DEFAULT_MAP) return 'Search for properties in Singapore';
  //   if (mode === MODE_UPCOMING) return 'View new and upcoming launches';
  //   return 'Unknown';
  // };

  const mediaQuery = checkMediaQuery();

  return (
    <>
      <div className="d-flex d-row justify-content-between">
        <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
          <div
            className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-5 py-5 text-12 lh-12"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,10"
            data-tooltip-id="map-tooltip"
            data-tooltip-html={`
              <div class="map-tooltip-content">
              <span class="fw-500">${theme} map</span>
              <br />
              <span class="map-tooltip-hint">Click to change map style</span></div>
            `}
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
          >
            <span className="js-dropdown-map">
              <img id="map-mode-ic" className="map-mode-icon" height={25} width={25} src={MAP_THEMES.find(t => t.label === theme).icon} />
            </span>
            {!mediaQuery && <i className="icon icon-chevron-sm-down text-7 ml-10" />}
          </div>
          <div className="toggle-element -dropdown dropdown-menu dropdown-top">
            <div className="text-12 y-gap-20 js-dropdown-list">
              {MAP_THEMES.map((option, index) => (
                <div key={index}>
                  <button
                    className={`d-block js-dropdown-link ${
                      theme === option.label ? "text-blue-1 " : ""
                    }`}
                    onClick={() => {
                      setTheme(option.label);
                      document.getElementById('map-mode-ic').src = option.icon;
                    }}
                  >
                    <img className="map-mode-icon mr-20" height={25} width={25} src={option.icon} />
                    {option.label}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
          {mediaQuery
            && <div
              className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
              aria-expanded="false"
              data-bs-offset="0,10"
            >
              <div className="map-color-legend map-color-grad">
                <span className="low">HIGH</span>
                <span className="high">LOW</span>
              </div>
              <div className="map-color-legend map-color-na ml-5">
                <span className="">NA</span>
              </div>
              <i className="icon icon-chevron-sm-down text-7 ml-5" />
            </div>
          }
          {!mediaQuery
            && <div
              className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
              aria-expanded="false"
              data-bs-offset="0,10"
              data-tooltip-id="map-tooltip"
              data-tooltip-html={`
                <div class="map-tooltip-content">
                <span class="fw-500">Color by Profitable %</span>
                <div class="map-tooltip-l-content text-12">
                <span class="text-14">Color range for % transactions
                <br />
                which are profitable:</span>
                <br />
                <span class="map-marker-color-red">RED: > 90%</span>
                <br />
                <span class="map-marker-color-orange">ORANGE: > 70%</span>
                <br />
                <span class="map-marker-color-yellow">YELLOW: > 50%</span>
                <br />
                <span class="map-marker-color-blue">BLUE: < 50%</span>
                <br />
                <span>GREY: No Data</span>
                </div>
              `}
              data-tooltip-variant="dark"
              data-tooltip-place="bottom"
            >
              <div className="map-color-legend map-color-grad">
                <span className="low">HIGH</span>
                <span className="high">LOW</span>
              </div>
              <div className="map-color-legend map-color-na ml-5">
                <span className="">NA</span>
              </div>
              <i className="icon icon-chevron-sm-down text-7 ml-5" />
            </div>
          }
          <div className="toggle-element -dropdown dropdown-menu dropdown-top">
            <div className="text-12 y-gap-20 js-dropdown-list">
              {markerSchemeOptions.map((option, index) => (
                <div key={index}>
                  <button
                    className={`d-block js-dropdown-link ${
                      markerScheme === option.id ? "text-blue-1 " : ""
                    }`}
                    onClick={() => {
                      setMarkerScheme(option.id);
                    }}
                    data-tooltip-id="map-tooltip"
                    data-tooltip-html={`
                      <div class="map-tooltip-content">
                      Show map marker color range for ${option.label}
                      </div>
                    `}
                    data-tooltip-variant="dark"
                    data-tooltip-place="bottom"
                  >
                    {option.label}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="p-2 flex-grow-1"></div>

        {mode === MODE_DEFAULT_MAP && !target
          && <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
            <div
              className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5 text-12 lh-12 text-blue-1"
              data-bs-toggle="offcanvas"
              data-bs-target="#mapSidebar"
              onClick={() => onSelectFilter({ type: 'filter' })}
            >
              <span className="js-dropdown-title">
                <i className="icon icon-menu text-12" />
                <span className="ml-5 text-12 fw-600">Filter</span>
              </span>
            </div>
          </div>
        }

        {/* <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
          <div
            className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-5 py-5 text-12 lh-12"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,10"
            data-tooltip-id="map-tooltip"
            data-tooltip-html={`
              <div class="map-tooltip-content">
              <span class="map-tooltip-hint">Click to change map mode</span>
              </div>
            `}
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
          >
            <span className="js-dropdown-map map-mode-sel fw-500 text-blue-1 pl-5">
              {getMapModeLabelShort(mode)}
            </span>
            <i className="icon icon-chevron-sm-down text-7 ml-10" />
          </div>
          <div className="toggle-element -dropdown dropdown-menu dropdown-top">
            <div className="text-12 y-gap-20 js-dropdown-list">
              {MAP_MODES.map((option, index) => (
                <div key={index}>
                  <button
                    className={`d-block js-dropdown-link ${
                      mode === option ? "text-blue-1 " : ""
                    }`}
                    onClick={() => {
                      setMode(option);
                      document.querySelector(".map-mode-sel").textContent = getMapModeLabelShort(option);
                    }}
                    data-tooltip-id="map-tooltip"
                    data-tooltip-html={`
                      <div class="map-tooltip-content">
                      ${getMapModeHint(option)}
                      </div>
                    `}
                    data-tooltip-variant="dark"
                    data-tooltip-place="bottom"
                  >
                    {getMapModeLabelShort(option)}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default MapButtons;
