import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

import DefaultHeader from "@/components/header/default-header";
import MetaComponent from "@/components/common/MetaComponent";
import Loader from "@/components/common/Loader";
import ErrorContent from "@/components/mapv2/error/ErrorContent";
import {
  getHomePropertyMediaStyle,
  getHomeMapMediaStyle,
  continueIfAllowedUser,
  noSelectClass
} from "@/utils/user";
import MainMap from "@/components/mapv2/MainMap";
import { downloadFileUrl, searchSearchSpecificAmenity } from "@/utils/api";
import {
  MAP_FILTER_OPTIONS,
  MODE_DEFAULT_MAP,
  MODE_UPCOMING,
  decompressAdvMapData,
  decompressBasicMapData,
  decompressGroupCompProfitData,
  decompressGroupCompTxData,
  decompressUpcomingMapData,
  getPropertyFileName,
  groupMapData
} from "@/utils/map";
import FilterBox from "@/components/mapv2/search/FilterBox";
import { convertMapUrl } from "@/utils/url";
import PropertyView from "@/components/mapv2/PropertyView";
import {
  LOCATION_AREA,
  LOCATION_BLOCK,
  LOCATION_COMPARE,
  LOCATION_MARKER,
  LOCATION_PROPERTY,
  LOCATION_SCHOOL,
  LOCATION_STATION,
  LOCATION_UPCOMING,
  getBlockUnitFromAddress
} from "@/utils/areas";
import { initializeFilters } from "@/utils/filter";
import UpcomingView from "@/components/mapv2/UpcomingView";
import AreaView from "@/components/mapv2/AreaView";
import AmenityView from "@/components/mapv2/AmenityView";
import CompareProView from "@/components/mapv2/CompareProView";
import MarkerView from "@/components/mapv2/MarkerView";

const metadata = {
  title: "REALSMART.SG | Search Properties | Supercharge your property search",
  description: "REALSMART.SG - Supercharge your property search",
};

const HomeMap = ({
  user,
  session
}) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const getDefaultTarget = (params) => {
    if (params.get('id')) {
      if (params.get('project')) return {
        id: params.get('id'),
        project: params.get('project'),
        type: LOCATION_PROPERTY
      };
      else return {
        id: params.get('id'),
        type: LOCATION_MARKER
      };
    }
    if (params.get('sch')) {
      return {
        id: params.get('sch'),
        type: LOCATION_SCHOOL
      };
    }
    if (params.get('stn')) {
      return {
        id: params.get('stn'),
        type: LOCATION_STATION
      };
    }
    return null;
  };

  const [hasInit, setHasInit] = useState(false);
  const [mode, setMode] = useState(MODE_DEFAULT_MAP);
  const [target, setTarget] = useState(getDefaultTarget(params));
  const [focus, setFocus] = useState(null);
  const [compareList, setCompareList] = useState({});

  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [upcomingData, setUpcomingData] = useState(null);
  const [hasAdvMapData, setHasAdvMapData] = useState(false);
  const [propIndex, setPropIndex] = useState(null);
  // const [mapGridData, setMapGridData] = useState(null);
  const [filterSelected, setFilterSelected] = useState(initializeFilters(MAP_FILTER_OPTIONS, params));

  const [isMinimized, setIsMinimized] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const isMinimizedRef = useRef(isMinimized);
  const [mapMediaStyle, setMapMediaStyle] = useState(getHomePropertyMediaStyle(target));
  const [screenDim, setScreenDim] = useState({ height: window.innerHeight, width: window.innerWidth });

  const getTargetCoord = (target) => {
    searchSearchSpecificAmenity(target.id, (data) => {
      if (data) {
        const newTarget = {
          ...target,
          ...data,
          lat: data.coords[0],
          lng: data.coords[1],
        };
        setTarget(newTarget);
        setFocus({
          target: newTarget
        });
      }
    }, () => {
      // do nothing
    });
  };

  /* Load map */
  const loadMapData = () => {
    setErr(null);
    setLoading(true);
    fetch(
      'https://realsmart.global.ssl.fastly.net/d/m/e'
    )
    .then(resp => {
      return resp.text();
    }).then(txt => {
      const projectData = decompressBasicMapData(txt);
      const mapData = groupMapData(projectData);
      const index = {};
      for (let i = 0; i < mapData.length; i++) {
        index[mapData[i].name] = i;
      }
      setMapData(mapData);
      setPropIndex(index);
      if (target?.type === LOCATION_PROPERTY) {
        const property = mapData.find(p => p.name === target.id);
        setFocus({
          target: property
        });
      } else if (target?.type === LOCATION_SCHOOL || target?.type === LOCATION_STATION) {
        if (!target?.lat && !target?.lng) {
          getTargetCoord(target);
        }
      }
      // setMapGridData(convertLatLngGrids(mapData));
      setLoading(false);
    }).catch(err => {
      setErr('Failed to load map');
      setLoading(false);
    });
  };

  const loadAdvMapData = (callback) => {
    setErr(null);
    setLoading(true);
    fetch(
      'https://realsmart.global.ssl.fastly.net/d/m/b'
    )
    .then(resp => {
      return resp.text();
    }).then(txt => {
      const data = decompressAdvMapData(txt);
      const newData = data.map((d, i) => ({
        ...mapData[i],
        ...d
      }))
      setMapData(newData);
      setHasAdvMapData(true);
      callback(newData);
      setLoading(false);
    }).catch(err => {
      setErr('Failed to load map data');
      setLoading(false);
    });
  };

  useEffect(() => {
    continueIfAllowedUser(user, () => {
      setTimeout(() => {
        if (!mapData) loadMapData();
      }, 288);
    }, navigate, pathname);
  }, [user]);

  useEffect(() => {
    if (hasInit) {
      if (mode === MODE_DEFAULT_MAP) {
        closePropertyDetails();
      }
    } else {
      setHasInit(true);
    }
  }, [mode]);

  /* Handle screen sizing */
  useEffect(() => { isMinimizedRef.current = isMinimized }, [isMinimized]);

  const handleResize = () => {
    if (!isMinimizedRef.current) setMapMediaStyle(getHomePropertyMediaStyle(target));
    setScreenDim({ height: window.innerHeight, width: window.innerWidth });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /* Handle property window */
  const handleMinimize = () => {
    setMapMediaStyle(getHomeMapMediaStyle());
    setIsMinimized(true);
    setIsMaximized(false);
  };

  const handleDefault = () => {
    setMapMediaStyle(getHomePropertyMediaStyle(target));
    setIsMinimized(false);
    setIsMaximized(false);
  };

  const handleMaximize = () => {
    setIsMaximized(true);
    setIsMinimized(false);
  };

  const closePropertyDetails = () => {
    setTarget(null);
    setFocus(null);
    setMapMediaStyle(getHomeMapMediaStyle());
    convertMapUrl(null);
  }

  /* Map interaction */
  const goToProperty = (markerName, projectId) => {
    if (target?.type === LOCATION_PROPERTY && markerName === target?.id && projectId === target?.project) return;
    const markerData = mapData.find(p => p.name === markerName);
    const newTarget = {
      id: markerName,
      project: projectId,
      type: LOCATION_PROPERTY,
      data: markerData
    };
    setTarget(newTarget);
    setMapMediaStyle(getHomePropertyMediaStyle(newTarget));
    if (isMaximized || isMinimized) handleDefault();
    setFocus({
      target: { ...markerData, id: markerName }
    });
    convertMapUrl(newTarget);
  };

  const goToMarker = (markerName) => {
    if (target?.type === LOCATION_MARKER && markerName === target?.id) return;
    const markerData = mapData.find(p => p.name === markerName);
    if (markerData.projects.length > 1) {
      const newTarget = {
        id: markerName,
        type: LOCATION_MARKER,
        data: markerData
      };
      setTarget(newTarget);
      setMapMediaStyle(getHomePropertyMediaStyle(newTarget));
      if (isMaximized || isMinimized) handleDefault();
      setFocus({
        target: { ...markerData, id: markerName }
      });
      convertMapUrl(newTarget);
    } else {
      goToProperty(markerName, markerData.projects[0].projectId);
    }
  };

  const goToUpcomingProperty = (project) => {
    const newTarget = {
      id: project.name,
      type: LOCATION_UPCOMING,
      data: project
    };
    setTarget(newTarget);
    setMapMediaStyle(getHomePropertyMediaStyle(newTarget));
    if (isMaximized || isMinimized) handleDefault();
    setFocus({
      target: { ...project, id: project.name }
    });
    convertMapUrl(null);
  };

  const goToArea = (area) => {
    const newTarget = {
      ...area,
      id: area.id,
      type: LOCATION_AREA
    };
    setTarget(newTarget);
    setMapMediaStyle(getHomePropertyMediaStyle(newTarget));
    if (isMaximized || isMinimized) handleDefault();
    setFocus({
      target: area,
      type: LOCATION_AREA
    });
    convertMapUrl(null);
  };

  const goToStation = (station) => {
    const newTarget = {
      ...station,
      id: station.id,
      type: LOCATION_STATION,
      lat: station.coords[0],
      lng: station.coords[1]
    };
    setTarget(newTarget);
    setMapMediaStyle(getHomePropertyMediaStyle(newTarget));
    if (isMaximized || isMinimized) handleDefault();
    setFocus({
      target: {
        ...station,
        id: station.name,
        lat: station.coords[0],
        lng: station.coords[1]
      }
    });
    convertMapUrl(newTarget);
  };

  const goToSchool = (school) => {
    const newTarget = {
      ...school,
      id: school.id,
      type: LOCATION_SCHOOL,
      lat: school.coords[0],
      lng: school.coords[1]
    };
    setTarget(newTarget);
    setMapMediaStyle(getHomePropertyMediaStyle(newTarget));
    if (isMaximized || isMinimized) handleDefault();
    setFocus({
      target: {
        ...school,
        id: school.name,
        lat: school.coords[0],
        lng: school.coords[1]
      }
    });
    convertMapUrl(newTarget);
  };

  /* Search bar interaction */
  const onSearch = (location) => {
    if (location.type === LOCATION_PROPERTY) {
      goToProperty(location.marker, location.store);
    } else if (location.type === LOCATION_SCHOOL) {
      goToSchool(location);
    } else if (location.type === LOCATION_STATION) {
      goToStation(location);
    }
    // TODO change to prop/sch/stn
    // logNameSearch('DETAIL', session, searchTerm.name);
    // goToProperty(searchTerm.name, searchTerm.address);
  };

  const setFocusOn = (markerName) => {
    const prop = mapData.find(d => d.name === markerName);
    if (prop) {
      setFocus({ target: prop });
    }
  };

  const addToCompareList = (projectName) => {
    if (projectName in compareList) return;
    downloadFileUrl('data/comp', getPropertyFileName(projectName), null, (url) => {
      fetch(url)
      .then(resp => resp.json())
      .then(json => {
        const propTx = decompressGroupCompTxData(json.t);
        const propProfit = decompressGroupCompProfitData(json.p);
        setCompareList({ ...compareList, [projectName]: {
          t: propTx,
          p: propProfit
        } });
      })
      .catch((e) => {
      })
    }, (err) => {
    });
  };

  const viewComparePro = () => {
    setTarget({ type: LOCATION_COMPARE });
  };

  const getMarkerData = (markerName) => mapData[propIndex[markerName]];

  const setMarkerData = (markerName) => setTarget({
    ...target,
    data: getMarkerData(markerName)
  });

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="header-margin"></div>
      <DefaultHeader user={user} />

      {/* loading screen */}
      {loading && <div className="loader-container"><Loader /></div>}

      {/* error screen */}
      {err && <ErrorContent />}

      {!loading && !err
        && <>
          {/* search bar */}
          <div className="py-10 bg-dark-2">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <FilterBox onSearch={onSearch} />
                </div>
              </div>
            </div>
          </div>

          <section className={noSelectClass(user)}>
            <div className="proj-bg-map">
              <MainMap
                user={user}
                target={target}
                focus={focus}
                setFocus={setFocus}
                mapMediaStyle={mapMediaStyle}
                mapData={mapData}
                upcomingData={upcomingData}
                goToProperty={goToMarker}
                goToUpcomingProperty={goToUpcomingProperty}
                goToArea={goToArea}
                goToStation={goToStation}
                goToSchool={goToSchool}
                isMinimized={isMinimized}
                isMaximized={isMaximized}
                mode={mode}
                setMode={setMode}
                setLoading={setLoading}
                setErr={setErr}
                closePropertyDetails={closePropertyDetails}
                loadAdvMapData={loadAdvMapData}
                hasAdvMapData={hasAdvMapData}
                filterSelected={filterSelected}
                setFilterSelected={setFilterSelected}
                propIndex={propIndex}
                compareList={compareList}
                viewComparePro={viewComparePro}
                // mapGridData={mapGridData}
              />

              {target?.type === LOCATION_PROPERTY
                && <PropertyView
                  user={user}
                  session={session}
                  target={target}
                  marker={target.data}
                  setMarkerData={setMarkerData}
                  focus={focus}
                  setFocus={setFocus}
                  setFocusOn={setFocusOn}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                  goToProperty={goToProperty}
                  screenDim={screenDim}
                  compareList={compareList}
                  setCompareList={setCompareList}
                  addToCompareList={addToCompareList}
                  mapData={mapData[propIndex[target.id]]}
                  viewComparePro={viewComparePro}
                />
              }

              {target?.type === LOCATION_MARKER
                && <MarkerView
                  user={user}
                  session={session}
                  target={target}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                  goToProperty={goToProperty}
                />
              }

              {target?.type === LOCATION_UPCOMING
                && <UpcomingView
                  user={user}
                  session={session}
                  target={target}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                  goToProperty={goToProperty}
                />
              }

              {target?.type === LOCATION_AREA
                && <AreaView
                  user={user}
                  session={session}
                  target={target}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                  goToProperty={goToProperty}
                />
              }

              {(target?.type === LOCATION_STATION || target?.type === LOCATION_SCHOOL)
                && <AmenityView
                  user={user}
                  session={session}
                  target={target}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                  goToProperty={goToProperty}
                  goToBlock={(d) => {
                    const idx = d.cell.row.index;
                    let block = getBlockUnitFromAddress(d.data[idx].street);
                    const match = block.block.match(/^\S+/);
                    block = match ? match[0] : block.block;
                    setFocus({
                      target: {
                        ...d.data[idx],
                        id: d.data[idx].street,
                        block: block,
                        type: LOCATION_BLOCK
                      },
                      links: [target]
                    })
                  }}
                />
              }

              {target?.type === LOCATION_COMPARE
                && <CompareProView
                  user={user}
                  screenDim={screenDim}
                  isMaximized={isMaximized}
                  isMinimized={isMinimized}
                  goToProperty={goToProperty}
                  setFocusOn={setFocusOn}
                  compareList={compareList}
                  setCompareList={setCompareList}
                  closeDetails={() => setTarget(null)}
                />
              }
            </div>
          </section>
        </>
      }
    </>
  );
};

export default HomeMap;
