import { useState } from "react";
import PropTypes from "prop-types";
import { MAP_THEMES } from "@/utils/areas";

const MapModeSelect = ({
  setTimeDistanceMode,
  displayAmenities,
  setDisplayAmenities,
  theme,
  setTheme
}) => {
  const amenities = [
    "Schools",
    "Transport",
    "Shopping",
    "Sports",
    'Properties'
  ];

  const options = [
    "Time taken by walking",
    "Distance from property",
  ];

  const labels = [
    'Time Taken',
    'Distance'
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const onToggleAmenityType = (e, option) => {
    const { checked } = e.target;
    setDisplayAmenities({
      ...displayAmenities,
      [option]: checked,
    });
  };
  
  return (
    <>
      <div className="d-flex d-row dropdown-map-container">
        <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
          <div
            className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-5 py-5 text-12 lh-12"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,10"
            data-tooltip-id="map-tooltip"
            data-tooltip-html={`
              <div class="map-tooltip-content">${theme} map
              <br />
              <span class="map-tooltip-hint">Click to change map style</span></div>
            `}
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
          >
            <span className="js-dropdown-map">
              <img id="map-mode-ic" className="map-mode-icon" height={25} width={25} src={MAP_THEMES.find(t => t.label === theme).icon} />
            </span>
            <i className="icon icon-chevron-sm-down text-7 ml-10" />
          </div>
          <div className="toggle-element -dropdown dropdown-menu dropdown-top">
            <div className="text-12 y-gap-20 js-dropdown-list">
              {MAP_THEMES.map((option, index) => (
                <div key={index}>
                  <button
                    className={`d-block js-dropdown-link ${
                      theme === option.label ? "text-blue-1 " : ""
                    }`}
                    onClick={() => {
                      setTheme(option.label);
                      document.getElementById('map-mode-ic').src = option.icon;
                    }}
                  >
                    <img className="map-mode-icon mr-20" height={25} width={25} src={option.icon} />
                    {option.label}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
          <div
            className="dropdown__button dropdown__button_sm d-flex items-center bg-white border-light rounded-100 px-15 py-5 text-12 lh-12"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,10"
            data-tooltip-id="map-tooltip"
            data-tooltip-html={`
              <div class="map-tooltip-content">Show or hide amenities
              <br />
              <span class="map-tooltip-hint">Click to change what to show on map</span></div>
            `}
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
          >
            <span className="js-dropdown-amenities">Filter Amenities</span>
            <i className="icon icon-chevron-sm-down text-7 ml-10" />
          </div>
          <div className="toggle-element -dropdown dropdown-menu dropdown-top">
            <div className="text-13 y-gap-20 js-dropdown-list">
              {amenities.map((option, index) => (
                <div key={index}>
                  <div className="form-checkbox d-flex items-center">
                    <input
                      type="checkbox"
                      checked={displayAmenities[option]}
                      onChange={e => onToggleAmenityType(e, option)}
                    />
                    <div className="form-checkbox__mark">
                      <div className="form-checkbox__icon icon-check" />
                    </div>
                    <div className="text-12 ml-10">{option}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
          <div
            className="dropdown__button dropdown__button_sm d-flex items-center bg-white border-light rounded-100 px-15 py-5 text-12 lh-12"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,10"
          >
            <span className="js-dropdown-title">{labels[options.indexOf(selectedOption)]}</span>
            <i className="icon icon-chevron-sm-down text-7 ml-10" />
          </div>
          <div className="toggle-element -dropdown dropdown-menu dropdown-top">
            <div className="text-12 y-gap-20 js-dropdown-list dropdown-top">
              {options.map((option, index) => (
                <div key={index}>
                  <button
                    className={`d-block js-dropdown-link ${
                      selectedOption === option ? "text-blue-1 " : ""
                    }`}
                    onClick={() => {
                      setSelectedOption(option);
                      setTimeDistanceMode(option);
                      document.querySelector(".js-dropdown-title").textContent = labels[index];
                    }}
                  >
                    {option}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div> */}

        <div className="dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
          <div
            className="button -sm -dark-1 bg-white text-blue-1 border-light rounded-100 text-15 px-10 py-5"
            data-bs-toggle="offcanvas"
            data-bs-target="#mapSearchSidebar"
          >
            <span className="js-dropdown-title">
              <i className="icon icon-search" />
            </span>
          </div>
        </div>

      </div>
    </>
  );
};

MapModeSelect.propTypes = {
  setTimeDistanceMode: PropTypes.func.isRequired,
  displayAmenities: PropTypes.object.isRequired,
  setDisplayAmenities: PropTypes.func.isRequired,
};

export default MapModeSelect;
