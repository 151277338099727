import { noSelectClass } from "@/utils/user";
import PerformancePanel from "./Overview/PerformancePanel";
import PropertyMap from "./Overview/PropertyMap";
import Factsheet from "./Overview/Factsheet";
import PropertyButtons from "./Overview/PropertyButtons";
import { useCallback, useEffect, useState } from "react";
import { deepCopy } from "@/utils/convert";

const PROPERTY_BUTTONS = [
  {
    id: 'compare',
    label: 'Add to Compare PRO',
    altLabel: 'Remove from Compare PRO',
    icon: 'icon-plus'
  },
  // {
  //   id: 'subscribe',
  //   label: 'Susbcribe',
  //   icon: 'icon-email-2'
  // },
  // {
  //   id: 'like',
  //   label: 'Like',
  //   icon: 'icon-like'
  // },
  {
    id: 'share',
    label: 'Share',
    icon: 'icon-share'
  }
];

const OverviewTab = ({
  user,
  data,
  marker,
  isMaximized,
  screenDim,
  compareList,
  setCompareList,
  addToCompareList,
  goToProperty
}) => {
  const [selection, setSelection] = useState({
    compare: data.name in compareList
  });

  const [selectedImg, setSelectedImg] = useState(null);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;

  const chartColGrid = (screenDim.width > screenDim.height)
    && (isMaximized ? screenDim.width > 1200 : screenDim.width > 1600) ? 6 : 12;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  }
  
  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);
  
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) setSelectedImg((selectedImg + 1) % data.gallery.length);
    if (isRightSwipe) setSelectedImg((selectedImg - 1) % data.gallery.length);
  }

  const keyPressed = useCallback(e => {
    if (selectedImg === null) return;
    e.preventDefault();
    if (e.key === 'Escape') setSelectedImg(null);
    if (e.key === 'ArrowLeft') setSelectedImg((selectedImg - 1) < 0 ? (data.gallery.length - 1) : (selectedImg - 1));
    if (e.key === 'ArrowRight') setSelectedImg((selectedImg + 1) % data.gallery.length);
  }, [selectedImg]);

  useEffect(() => {
    document.addEventListener("keydown", keyPressed, false);
    return () => {
      document.removeEventListener("keydown", keyPressed, false);
    };
  }, [keyPressed]);

  const getStreetsLabel = (streets) => streets?.length > 10 ? (
    <div
      className="d-flex items-center text-12 text-light-1 cursor-pointer"
      data-tooltip-id="map-tooltip"
      data-tooltip-html={`<span class="text-10">${data.streets.map((s, i) => i % 5 === 4 ? `${s},<br />` : (i !== data.streets.length - 1 ? `${s}, `: s)).join('')}</span>`}
      data-tooltip-variant="dark"
      data-tooltip-place="bottom"
    >
      <i className="icon-location-2 text-14 mr-5" />
      {data.streets.slice(0, 10).join(', ')} +{data.streets.length - 10} more
    </div>
  ) : (
    <div className="d-flex items-center text-12 text-light-1">
      <i className="icon-location-2 text-14 mr-5" />
      {data.streets?.join(', ')}
    </div>
  );

  const getTenuresLabel = (tenures) => tenures?.length > 3 ? (
    <div className="col-auto d-flex align-content-start flex-wrap py-0">
      {data.tenures?.slice(0, 3).map(tenure => (
        <div className="p-2 d-flex items-center text-10 text-white proj-tag px-10 rounded-100 fw-500 mr-10 mt-5 mb-5">
          {tenure}
        </div>
      ))}
      <div
        className="p-2 d-flex items-center text-10 text-white proj-tag px-10 rounded-100 fw-500 mr-10 mt-5 mb-5 cursor-pointer"
        data-tooltip-id="map-tooltip"
        data-tooltip-html={`<span class="text-10">${data.tenures.slice(3).join('<br />')}</span>`}
        data-tooltip-variant="dark"
        data-tooltip-place="bottom"
      >
        +{data.tenures.length - 3} more
      </div>
    </div>
  ) : (
    <div className="col-auto d-flex align-content-start flex-wrap py-0">
      {data.tenures?.map(tenure => (
        <div className="p-2 d-flex items-center text-10 text-white proj-tag px-10 rounded-100 fw-500 mr-10 mt-5 mb-5">
          {tenure}
        </div>
      ))}
    </div>
  );

  const onSelectButton = (option) => {
    if (option === 'share') {
      if (window.navigator.share) {
        window.navigator.share({
          title: `REALSMART.SG - ${data.name}`,
          text: `Get real estate data and insights on REALSMART. Free Forever`,
          url: `https://realsmart.sg/app?id=${encodeURIComponent(data.name)}&r=${user?.claims?.user_id}`
        });
      } else {
        // TODO fallback view
      }
    } else if (option === 'compare') {
      if (selection.compare) {
        const newCompareList = deepCopy(compareList);
        delete newCompareList[data.name];
        setCompareList(newCompareList);
        setSelection({...selection, compare: false});
      } else {
        addToCompareList(data.name);
        setSelection({...selection, compare: true});
      }
    }
  };

  return (
    <>
      <section className="pt-0" id="reviews">
        <div className={`px-5 ${noSelectClass(user)}`}>
          <div className="row y-gap-10 justify-between">
            <div className="col-12 row x-gap-50 y-gap-10 py-0">
              <div className="row justify-between items-end">
                <div className="col-auto">
                  <div className="row x-gap-20  items-center">
                    <div className="col-auto">
                      
                      <div className="dropdown js-dropdown js-category-active heatmap-selector-menu mt-10 mb-10 map-mode-selector">
                        <div
                          className="dropdown__button d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-14 lh-12"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="true"
                          aria-expanded="false"
                          data-bs-offset="0,10"
                        >
                          <span className="js-dropdown-psel-title text-16 sm:text-20 fw-500">
                            {data.name}
                          </span>
                          <i className="icon icon-chevron-sm-down text-7 ml-10" />
                        </div>
                        <div className="toggle-element -dropdown  dropdown-menu">
                          <div className="text-13 y-gap-15 js-dropdown-list">
                            {marker.projects.map((option, index) => (
                              <div key={index}>
                                <button
                                  className={`d-block js-dropdown-link ${
                                    data.name === option.project ? "text-blue-1 " : ""
                                  }`}
                                  onClick={() => {
                                    document.querySelector(".js-dropdown-psel-title").textContent = option.project;
                                    goToProperty(marker.name, option.projectId);
                                  }}
                                >
                                  {option.project}
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="row x-gap-20 y-gap-0 items-center px-10 mb-10">
                    <div className="col-auto">
                      {getStreetsLabel(data.streets)}
                    </div>
                    {getTenuresLabel(data.tenures)}
                  </div>
                </div>
              </div>
              
            </div>

            <div className="col-12 pt-10">
              <PropertyButtons
                options={PROPERTY_BUTTONS}
                selection={selection}
                onSelect={onSelectButton}
              />
            </div>

            <div className={`col-${chartColGrid} text-blue-1`}>
              <PerformancePanel data={data} />
            </div>

            {chartColGrid === 6
              && <div className={`col-${chartColGrid} text-blue-1`}>
                <div className="mt-5" style={{ height: data.score !== null ? 173 : 201 } }>
                  <PropertyMap data={data} zoom={8.8} />
                </div>
              </div>
            }

            {chartColGrid !== 6 && !data?.gallery?.length
              && <div className="col-12">
                <div className="proj-gallery">
                  <PropertyMap data={data} />
                </div>
              </div>
            }

            {data?.gallery?.length > 0
              && <div className="col-12">
                <div className="photo-gallery d-flex">
                  {data.gallery.map((d, i) => (
                    <img
                      loading="lazy"
                      src={d.url}
                      onClick={() => setSelectedImg(i)}
                    />
                  ))}
                </div>
              </div>
            }

            {selectedImg !== null
              &&  <div
                style={{
                  position: 'fixed',
                  top: 60,
                  left: 0,
                  width: '100%',
                  height: 'calc(100svh - 60px)',
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1000,
                  cursor: 'pointer'
                }}
                onClick={() => setSelectedImg(null)}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
              >
                <button
                  onClick={() => setSelectedImg(null)}
                  style={{
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                    backgroundColor: 'transparent',
                    color: 'white',
                    border: 'none',
                    fontSize: '24px',
                    cursor: 'pointer',
                  }}
                >
                  <i className="icon-close" />
                </button>

                <button
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedImg((selectedImg - 1) % data.gallery.length);
                  }}
                  style={{
                    position: 'absolute',
                    bottom: '20px',
                    left: '20px',
                    backgroundColor: 'transparent',
                    color: 'white',
                    border: 'none',
                    fontSize: '24px',
                    cursor: 'pointer',
                  }}
                >
                  <i className="icon-chevron-left" />
                </button>

                <button
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedImg((selectedImg + 1) % data.gallery.length);
                  }}
                  style={{
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: 'transparent',
                    color: 'white',
                    border: 'none',
                    fontSize: '24px',
                    cursor: 'pointer',
                  }}
                >
                  <i className="icon-chevron-right" />
                </button>

                <img
                  src={data.gallery[selectedImg].fullsize}
                  style={{
                    maxHeight: '90%',
                    maxWidth: '90%',
                    cursor: 'default'
                  }}
                  onClick={e => e.stopPropagation()}
                />
              </div>
            }

            <Factsheet data={data} isMaximized={isMaximized} screenDim={screenDim} />
          </div>
        </div>
      </section>
    </>
  );
};

export default OverviewTab;
