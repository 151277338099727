import { Marker } from "react-map-gl/maplibre";

const StationMarker = ({ idx, station, onClick }) => {
  let subtypeIcon = 'mrt';
  if (station.subtype.indexOf('LRT') >= 0) subtypeIcon = 'lrt';

  const chunks = station.names[0].split(' EXIT ');
  let exitLabel = null;
  if (chunks.length > 1) exitLabel = chunks[1];

  return (
    <Marker
      key={`stm-${idx}`}
      latitude={station.coords[0]}
      longitude={station.coords[1]}
      anchor="bottom"
      onClick={onClick}
    >
      <div
        className="cursor-pointer"
        data-tooltip-id="amenity-tooltip"
        data-tooltip-html={`
          <div class="map-tooltip-content">
          <span class="fw-500">${station.names[0]}</span>
          </div>
        `}
        data-tooltip-variant="dark"
        data-tooltip-place="bottom"
      >
        <div>
          <img className="station-logo" src={`/img/general/ic_${subtypeIcon}.png`} width={30} />
        </div>
        {exitLabel && <span className="station-exit rounded-100 fw-600">EXIT {exitLabel}</span>}
      </div>
    </Marker>
  );
};

export default StationMarker;
