import { NA } from "@/utils/convert";
import { ICON, getPropertyMarkerColor } from "@/utils/map";
import { checkMediaQuery } from "@/utils/user";
import { Marker } from "react-map-gl/maplibre";

const ICON_TYPE_APARTMENT = 'apartment';

const PROPERTY_ICON = "M 12 2 A 1 1 0 0 0 11.289062 2.296875 L 1.203125 11.097656 A 0.5 0.5 0 0 0 1 11.5 A 0.5 0.5 0 0 0 1.5 12 L 4 12 L 4 20 C 4 20.552 4.448 21 5 21 L 9 21 C 9.552 21 10 20.552 10 20 L 10 14 L 14 14 L 14 20 C 14 20.552 14.448 21 15 21 L 19 21 C 19.552 21 20 20.552 20 20 L 20 12 L 22.5 12 A 0.5 0.5 0 0 0 23 11.5 A 0.5 0.5 0 0 0 22.796875 11.097656 L 12.716797 2.3027344 A 1 1 0 0 0 12.710938 2.296875 A 1 1 0 0 0 12 2 z";

const APARTMENT_ICON = "M246.125,195.063v-82.688h-63v82.688h-7.875V17.823h-23.625V2.125h-47.25v15.698H80.75v177.24h-7.875V80.875h-63v114.188H2 v11.813h252v-11.813H246.125z M47.281,177.344H35.469v-11.813h11.813V177.344z M47.281,145.844H35.469v-11.813h11.813V145.844z M47.281,114.344H35.469v-11.813h11.813V114.344z M119.141,174.391h-11.813v-11.813h11.813V174.391z M119.141,142.891h-11.813 v-11.813h11.813V142.891z M119.141,111.391h-11.813V99.578h11.813V111.391z M119.141,79.891h-11.813V68.078h11.813V79.891z M150.641,174.391h-11.813v-11.813h11.813V174.391z M150.641,142.891h-11.813v-11.813h11.813V142.891z M150.641,111.391h-11.813 V99.578h11.813V111.391z M150.641,79.891h-11.813V68.078h11.813V79.891z M220.531,177.344h-11.813v-11.813h11.813V177.344z M220.531,145.844h-11.813v-11.813h11.813V145.844z";

const pinStyle = {
  cursor: 'pointer',
  fill: '#DE3163',
  stroke: 'none',
};

const PROP_TYPE_APARTMENT = 'Apartment';
const PROP_TYPE_SEMI_DETACH = 'Semi-Detach House';
const PROP_TYPE_DETACHED = 'Detached House';
const PROP_TYPE_TERRACE = 'Terrace';
const PROP_TYPE_CONDO = 'Condominium';

const UpcomingMarker = ({
  idx,
  property,
  goToProperty
}) => {
  const hasType = (type) => property.types.indexOf(type) >= 0;

  const getPinColor = (prop) => {
    if (hasType(PROP_TYPE_APARTMENT)) return '#008009';
    if (hasType(PROP_TYPE_SEMI_DETACH)) return '#7e53f9';
    if (hasType(PROP_TYPE_DETACHED)) return '#927238';
    if (hasType(PROP_TYPE_TERRACE)) return '#003399';
    if (hasType(PROP_TYPE_CONDO)) return '#3554d1';
    return '#DE3163';
  };

  const getPinStyle = (prop) => {
    const style = { ...pinStyle };
    style.fill = getPinColor(prop);
    return style;
  };

  const getLabel = (prop) => {
    if (hasType(PROP_TYPE_APARTMENT)) return 'CONDO';
    if (hasType(PROP_TYPE_SEMI_DETACH)) return 'LANDED';
    if (hasType(PROP_TYPE_DETACHED)) return 'LANDED';
    if (hasType(PROP_TYPE_TERRACE)) return 'LANDED';
    if (hasType(PROP_TYPE_CONDO)) return 'CONDO';
    return 'EC';
  };

  const getIcon = (prop) => {
    if (hasType(PROP_TYPE_APARTMENT) || hasType(PROP_TYPE_CONDO)) return ICON_TYPE_APARTMENT;
    if (hasType(PROP_TYPE_SEMI_DETACH) || hasType(PROP_TYPE_DETACHED) || hasType(PROP_TYPE_TERRACE)) return ICON_TYPE_HOUSE;
    return ICON_TYPE_APARTMENT
  };

  return (
    <Marker
      key={`upcm-${idx}`}
      latitude={property.lat}
      longitude={property.lng}
      anchor="bottom"
      onClick={e => {
        e.originalEvent.stopPropagation();
        goToProperty(property);
      }}
    >
      <div
        data-tooltip-id="map-tooltip"
        data-tooltip-html={checkMediaQuery() ? `<div class="map-tooltip-content"><span class="fw-500">${property.name}</span></div>` : `
          <div class="map-tooltip-content">
          <span class="fw-500">${property.name}</span>
          <div class="map-tooltip-l-content text-14">
          Type: ${property.types.map(t => t === 'N.A.' ? 'Execute Condo' : t).join(', ')}
          <br />
          Completion: ${!property.completion ? NA : property.completion}
          <br />
          Profitable %: ${property.profitable === null || property.profitable < 0 ? NA : `${property.profitable}%`}
          </div>
          <span class="map-tooltip-hint">Click to view details</span>
          </div>
        `}
        data-tooltip-variant="dark"
        data-tooltip-place="bottom"
      >
        <svg
          height={60}
          viewBox="0 0 24 24"
          style={getPinStyle(property)}
        >
          <path d={ICON} />
          <circle cx="12" cy="10" r="8" fill="#fff" />
          <>
            <text x='12' y='15' textAnchor="middle" fill={getPinColor(property)} style={{ fontSize: 3, fontWeight: 700 }} >
              {getLabel(property)}
            </text>
            {
              getIcon(property) === ICON_TYPE_APARTMENT
                ? <g transform="scale(0.04),translate(168,78)">
                    <path d={APARTMENT_ICON} className="mapMarkerIcon" />
                  </g>
                : <g transform="scale(0.4),translate(18,7)">
                    <path d={PROPERTY_ICON} className="mapMarkerIcon" />
                  </g>
            }
          </>
        </svg>
      </div>
    </Marker>
  );
};

export default UpcomingMarker;
