import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Map, {
  Layer,
  Marker,
  Popup,
  Source
} from "react-map-gl/maplibre";
import { LngLatBounds } from "maplibre-gl";
import { debounce } from 'lodash';
import {
  DEFAULT_MAP_THEME,
  getMapStyleFromTheme,
  transformMapboxStyleRequest
} from "@/utils/areas";
import MapModeSelect from "@/components/project/MapModeSelect";
import {
  NA,
  calculateDist,
  formatDistanceLabel,
  getMidpoint
} from "@/utils/convert";
import { checkMediaQuery, noSelectClass } from "@/utils/user";
import { Tooltip } from "react-tooltip";
import * as turf from '@turf/turf';
import SchoolQueryFilter from "./SchoolQueryFilter";
import {
  getMarkerIcon,
  getMarkerScale,
  getMarkerStyle,
  homePinStyle,
  HOME_ICON,
  ICON,
  radiusLabelStyle,
  radiusMaskStyle,
  getMarkerPriColor,
  generateLinesData,
  PROPERTY_ICON,
  blockPinStyle,
  generateMultipleLinesData
} from "@/utils/map";
import { DEBOUNCE_TIMING } from "@/utils/api";
import AreaTable from "./AreaTable";

const DEFAULT_AREA_ZOOM = 14.5;

const DetailMap = ({
  id,
  user,
  mapMediaStyle,
  initialZoom,
  resultPropLocation,
  resultAllLocations,
  nearbyProperties,
  schools,
  comparable,
  focusLocation,
  highlightList,
  setHighlightList,
  blockMarker,
  clearBlockMarker,
  isMaximized,
  isMinimized,
  goToProperty,
  onMapBoundsChange,
  planningAreas,
  mapBoundProperties,
  defaultZoom,
  err
}) => {
  const mapRef = useRef();

  const [popupInfo, setPopupInfo] = useState(null);
  const [isWalkingMode, setIsWalkingMode] = useState(true);
  const [displayAmenities, setDisplayAmenities] = useState({
    "Schools": true,
    "Transport": true,
    "Shopping": false,
    "Sports": false,
    "Properties": true,
  });
  const [mapStyle, setMapStyle] = useState(DEFAULT_MAP_THEME);

  const [schoolSearchValue, setSchoolSearchValue] = useState(null);
  const [schoolSearched, setSchoolSearched] = useState([]);
  const [showSchoolLabel, setShowSchoolLabel] = useState(null);

  const [radiusMaskCoords, setRadiusMaskCoords] = useState(null);

  const [allowInteraction, setAllowInteraction] = useState(!id);

  const [zoom, setZoom] = useState(defaultZoom ?? 16);

  const [areaSelected, setAreaSelected] = useState(null);

  useEffect(() => {
    setPopupInfo(null);
  }, [id]);

  const flyTo = (coordinates, zoom = 16) => {
    const map = mapRef.current?.getMap();
    if (!map) return;
    setAllowInteraction(false);

    map.flyTo({
      center: coordinates,
      essential: true,
      zoom: zoom,
      transitionDuration: 388
    });
  };

  const createLocationList = (locations, schools) => {
    const defaultSchs = schools.map(s => s.name.toLowerCase());
    return locations.filter(v => v.type !== 'Schools'
      || !defaultSchs.some(s => v.name.toLowerCase().startsWith(s))
    );
  };

  const filterLocations = (locations) => locations.filter(marker => {
    if (marker.type === 'Schools'
      || marker.type === 'Sports'
      || marker.type === 'Transport'
      || marker.type === 'Shopping'
    ) {
      return displayAmenities[marker.type];
    }
    return true;
  });

  const isBlockMarker = (marker) => marker
    && (Array.isArray(marker) || !marker.type || marker.type === 'prop' || marker.type === 'school')
    && !!marker.props && !!marker.schools;

  const isProject = (marker) => marker?.default;

  const isProperty = (marker) => marker?.type === 'Property';

  const getMarkerText = (marker) => isWalkingMode
    ? `${parseInt(marker.walkTime / 60)} min`
    : formatDistanceLabel(marker.distance, true);

  const getLocationIcon = (location) => {
    if (location.type === 'Property') return 'home';
    return 'location';
  };

  const setTimeDistanceMode = (option) => setIsWalkingMode(option?.includes('walk'));

  const onSchoolSearchLocation = (school) => {
    setSchoolSearched([{ ...school, type: 'Schools' }]);
    setShowSchoolLabel(school);
    setRadiusMaskCoords({
      longitude: school.lng,
      latitude: school.lat
    });

    if (document.getElementById('mapSearchSidebar').classList.contains('show')) {
      document.getElementById('mapsearch-close-button').click();
    }

    flyTo([school.lng, school.lat]);
  };

  const showOpaqueMarker = (location) => isProject(location)
    || isProperty(location)
    || highlightList.length === 0
    || highlightList.find(item => (item.id && location.id) ? (item.id === location.id) : (item.name === location.name));

  const checkBlockMarker = (location) => !blockMarker
    || blockMarker.some(b => b.type !== 'school' && b.schools?.find(s => s.id === location.id))
    || blockMarker.some(b => b.type === 'school' && b.id === location.id);

  useEffect(() => {
    if (focusLocation && mapRef.current) {
      let zoom = focusLocation.zoom;
      let point = [focusLocation.lng, focusLocation.lat];
      if (focusLocation.target) {
        const minZoom = 14;
        const maxZoom = 16;
        const dist = calculateDist(resultPropLocation.lat, resultPropLocation.lng, focusLocation.lat, focusLocation.lng);
        const midpoint = getMidpoint(resultPropLocation.lat, resultPropLocation.lng, focusLocation.lat, focusLocation.lng);
        point = [midpoint.lng, midpoint.lat];
        zoom = (1 - Math.min(dist, 1000) / 1000) * (maxZoom - minZoom) + minZoom;
      }
      flyTo(point, zoom);
    }
  }, [focusLocation]);

  // const zoomAndClearAll = (location) => {
  //   flyTo([ focusLocation.lng, focusLocation.lat ], focusLocation.zoom ?? 16);
  //   setHighlightList([]);
  //   clearBlockMarker();
  //   setSchoolSearched([]);
  // };

  const closePopupPanel = () => {
    if (document.getElementById('propSidebar').classList.contains('show')) {
      document.getElementById('prop-close-button').click();
    }
  };

  const onMapMoveEnd = () => {
    if (allowInteraction && zoom > DEFAULT_AREA_ZOOM) {
      const bounds = mapRef.current.getBounds();
      const ne = bounds.getNorthEast(); // North-East corner (lat, lng)
      const sw = bounds.getSouthWest(); // South-West corner (lat, lng)
      onMapBoundsChange?.([sw.lat, ne.lat, sw.lng, ne.lng]);
    }
    setAllowInteraction(true);
  };

  const onChangeZoom = () => {
    const zoomLevel = mapRef.current.getZoom();
    setZoom(zoomLevel);
  };

  const onDebouncedZoom = useCallback(debounce(onChangeZoom, DEBOUNCE_TIMING), []);

  const onMapZoom = () => {
    if (allowInteraction) {
      onDebouncedZoom();
    }
  };

  const getMarkerColor = (marker) => {
    return '#DE3163';
  };

  return (
    <>
      {!allowInteraction && (
        <div
          style={{
            ...mapMediaStyle,
            position: 'absolute',
            top: '132px',
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            zIndex: 10,
            overflow: 'hidden'
          }}
        />
      )}
      <Map
        ref={mapRef}
        maxBounds={[103.3, 1.0, 104.3, 1.7]}
        mapStyle={getMapStyleFromTheme(mapStyle)}
        style={mapMediaStyle}
        initialViewState={initialZoom}
        attributionControl={false}
        transformRequest={transformMapboxStyleRequest}
        onMoveEnd={onMapMoveEnd}
        onZoom={onMapZoom}
      >
        {/* <ScaleControl position="bottom-left" />
        <NavigationControl position="bottom-left" />
        <FullscreenControl position="bottom-left" /> */}

        {radiusMaskCoords
          && <>
            <Source id="mask-data" type="geojson" data={turf.featureCollection([
              turf.polygon([[
                [-180, -90],
                [-180, 90],
                [180, 90],
                [180, -90],
                [-180, -90]
              ], turf.circle(
                [ radiusMaskCoords.longitude, radiusMaskCoords.latitude ], 1,
                { units: 'kilometers' }
              ).geometry.coordinates[0]])
            ])}>
              <Layer {...radiusMaskStyle} />
            </Source>

            <Source id="mask-label" type="geojson" data={{
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: turf.destination(
                      [ radiusMaskCoords.longitude, radiusMaskCoords.latitude ], 1, 0,
                      { units: 'kilometers' }
                    ).geometry.coordinates
                  },
                  properties: {
                    label: 'approx 1km radius'
                  }
                }
              ]
            }}>
              <Layer {...radiusLabelStyle} />
            </Source>
          </>
        }

        {highlightList && highlightList.length > 0
          && <Source id="highlight-path" type="geojson" data={generateLinesData(resultPropLocation, highlightList)}>
            <Layer {...{
              id: 'route',
              type: 'line',
              paint: {
                'line-color': '#0000ff',
                'line-width': 2,
                'line-dasharray': [2, 2],
              }
            }} />
          </Source>
        }

        {resultPropLocation && [
          ...filterLocations([
            ...createLocationList(resultAllLocations, schools.origin),
            ...schools.origin
          ]),
          resultPropLocation,
          ...(displayAmenities['Properties'] ? nearbyProperties : [])
        ].map((location, i) => (
          <Marker
            key={i}
            latitude={location.lat}
            longitude={location.lng}
            anchor="bottom"
            onClick={e => {
              e.originalEvent.stopPropagation();
              setPopupInfo(location);
              setShowSchoolLabel(null);
              setRadiusMaskCoords(null);
              flyTo(location);
            }}
          >
            <div
              style={{
                opacity: showOpaqueMarker(location) && checkBlockMarker(location) ? 1 : 0.2,
                transform: `scale(${isProject(location) || isProperty(location) ? 1 : 0.8})`
              }}
              data-bs-toggle="offcanvas"
              data-bs-target="#propSidebar"
              data-tooltip-id="map-tooltip"
              data-tooltip-html={`
                <div class="map-tooltip-content">${location.name}
                <br />
                <span class="map-tooltip-hint">Click to view details</span></div>
              `}
              data-tooltip-variant="dark"
              data-tooltip-place="bottom"
            >
              <svg
                height={60}
                viewBox="0 0 24 24"
                style={
                  isProject(location) ? homePinStyle : getMarkerStyle(location)
                }
              >
                <path d={ICON} />
                <circle cx="12" cy="10" r="8" fill="#fff" />
                {isProject(location)
                  && <g transform="scale(0.6),translate(10,6)">
                    <path d={HOME_ICON} className="mapMarkerIcon" />
                  </g>
                }
                {!isProject(location)
                  && <>
                    <text x='12' y='14' textAnchor="middle" fill={getMarkerPriColor(location)} style={{ fontSize: 4 }} >
                      {getMarkerText(location)}
                    </text>
                    <g transform={getMarkerScale(location)}>
                      <path d={getMarkerIcon(location)} className="mapMarkerIcon" />
                    </g>
                  </>
                }
              </svg>
            </div>
          </Marker>
        ))}

        {schoolSearched.map((school, i) => (
          <Marker
            key={`sch-${i}`}
            latitude={school.lat}
            longitude={school.lng}
            anchor="bottom"
          >
            <div>
              <svg
                height={60}
                viewBox="0 0 24 24"
                style={homePinStyle}
              >
                <path d={ICON} />
                <circle cx="12" cy="10" r="8" fill="#fff" />
                <g transform="scale(0.5),translate(14,9)">
                  <path d={getMarkerIcon(school)} className="mapMarkerIcon" />
                </g>
              </svg>
            </div>
          </Marker>
        ))}

        {showSchoolLabel
          && <Popup
            className="hide-popup-close-button ptr-none"
            anchor="top"
            latitude={showSchoolLabel.lat}
            longitude={showSchoolLabel.lng}
            onClose={() => {
              setShowSchoolLabel(null);
              setSchoolSearched([]);
              setRadiusMaskCoords(null);
            }}
          >
            <div className={`px-10 ${noSelectClass(user)} watermark popup-dialog ptr-auto text-center`}>
              <p className="text-14 pb-5 text-dark fw-600">
                {showSchoolLabel.name}
              </p>
              {showSchoolLabel.street
                && <p className="text-12 text-blue-1 fw-600 mb-5">
                  {showSchoolLabel.street}
                </p>
              }
            </div>
          </Popup>
        }

        {blockMarker && blockMarker.some(b => b.type !== 'school')
          && <>
            {blockMarker.map(b =>
              <Marker
                latitude={b.lat}
                longitude={b.lng}
                anchor="bottom"
                onClick={() => {
                  setPopupInfo(b);
                  setShowSchoolLabel(null);
                  setRadiusMaskCoords(null);
                  flyTo(b, 17);
                }}
              >
                <div
                  data-bs-toggle="offcanvas"
                  data-bs-target="#propSidebar"
                  data-tooltip-id="map-tooltip"
                  data-tooltip-html={`
                    <div class="map-tooltip-content">${b.address}
                    <br />
                    <span class="map-tooltip-hint">Click to view details</span></div>
                  `}
                  data-tooltip-variant="dark"
                  data-tooltip-place="bottom"
                >
                  <svg
                    height={60}
                    viewBox="0 0 24 24"
                    style={blockPinStyle}
                  >
                    <path d={ICON} />
                    <circle cx="12" cy="10" r="8" fill="#fff" />
                    <g transform="scale(0.5),translate(14,9)">
                      <path d={PROPERTY_ICON} className="mapMarkerIcon" />
                    </g>
                  </svg>
                </div>
              </Marker>
            )}

            <Source id="highlight-path-block-prop" type="geojson" data={
              generateMultipleLinesData(blockMarker, 'schools')}
            >
              <Layer {...{
                id: 'route',
                type: 'line',
                paint: {
                  'line-color': '#0000ff',
                  'line-width': 2,
                  'line-dasharray': [2, 2],
                }
              }} />
            </Source>
          </>
        }

        {blockMarker && blockMarker.some(b => b.type === 'school')
          && <>
            {blockMarker.map(b => (
              <>
                {b.props.map(p => (
                  <Marker
                    latitude={p.lat}
                    longitude={p.lng}
                    anchor="bottom"
                    onClick={() => {
                      setPopupInfo(p);
                      setShowSchoolLabel(null);
                      setRadiusMaskCoords(null);
                      flyTo(p, 17);
                    }}
                  >
                    <div
                      data-bs-toggle="offcanvas"
                      data-bs-target="#propSidebar"
                      data-tooltip-id="map-tooltip"
                      data-tooltip-html={`
                        <div class="map-tooltip-content">${p.address}
                        <br />
                        <span class="map-tooltip-hint">Click to view details</span></div>
                      `}
                      data-tooltip-variant="dark"
                      data-tooltip-place="bottom"
                    >
                      <svg
                        height={60}
                        viewBox="0 0 24 24"
                        style={blockPinStyle}
                      >
                        <path d={ICON} />
                        <circle cx="12" cy="10" r="8" fill="#fff" />
                        <g transform="scale(0.5),translate(14,9)">
                          <path d={PROPERTY_ICON} className="mapMarkerIcon" />
                        </g>
                      </svg>
                    </div>
                  </Marker>
                ))}
              </>
            ))}

            <Source id="highlight-path-block-school" type="geojson" data={
              generateMultipleLinesData(blockMarker, 'props')
            }>
              <Layer {...{
                id: 'route',
                type: 'line',
                paint: {
                  'line-color': '#0000ff',
                  'line-width': 2,
                  'line-dasharray': [2, 2],
                }
              }} />
            </Source>
          </>
        }

        {planningAreas && zoom <= DEFAULT_AREA_ZOOM
          && planningAreas.map((area, i) => (
            <Marker
              key={`p-area-${i}`}
              latitude={area.lat}
              longitude={area.lng}
              anchor="bottom"
              onClick={() => setAreaSelected(area)}
            >
              <div
                data-bs-toggle="offcanvas"
                data-bs-target="#areaSidebar"
                data-tooltip-id="map-tooltip"
                data-tooltip-html={`
                  <div class="map-tooltip-content">${area.properties.length} propert${area.properties.length > 1 ? 'ies' : 'y'} in ${area.id}
                  <br />
                  <span class="map-tooltip-hint">Click to view details</span></div>
                `}
                data-tooltip-variant="dark"
                data-tooltip-place="bottom"
              >
                <svg
                  height={60}
                  viewBox="0 0 24 24"
                  style={{
                    ...homePinStyle,
                    fill: getMarkerColor(area)
                  }}
                >
                  <path d={ICON} />
                  <circle cx="12" cy="10" r="8" fill="#fff" />
                  <text x='12' y='12.5' textAnchor="middle" fill={getMarkerColor(area)} style={{ fontSize: '7px', fontWeight: '600' }} >
                    {area.properties.length}
                  </text>
                </svg>
              </div>
            </Marker>
          ))
        }

        {mapBoundProperties && zoom > DEFAULT_AREA_ZOOM
          && mapBoundProperties.map((p, i) => (
            <Marker
              key={`p-area-${i}`}
              latitude={p.lat}
              longitude={p.lng}
              anchor="bottom"
            >
              <div>
                <svg
                  height={60}
                  viewBox="0 0 24 24"
                  style={{
                    ...homePinStyle,
                    fill: getMarkerColor(p)
                  }}
                >
                  <path d={ICON} />
                  <circle cx="12" cy="10" r="8" fill="#fff" />
                  <g transform="scale(0.5),translate(14,9)">
                    <path d={PROPERTY_ICON} className="mapMarkerIcon" />
                  </g>
                </svg>
              </div>
            </Marker>
          ))
        }

      </Map>

      <Tooltip id="map-tooltip" style={{ zIndex: 900 }} />

      <div
        className={`offcanvas offcanvas-start filter-bar-nopad ${noSelectClass(user)}`}
        tabIndex="-1"
        id="propSidebar"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasLabel">
            {popupInfo && <>
              {popupInfo.name ?? popupInfo.address}
            </>}
          </h5>
          <button
            id="prop-close-button"
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        {popupInfo
          && <div className="px-10 watermark h-100">

            {(isProject(popupInfo))
              && <>
                  <p className="px-10 text-dark-1 text-14 border-top-light">
                    <h5 className="text-16 py-10">Property Info 
                      <span className="ml-10 text-12 lh-12 items-center text-white proj-beta noselect rounded-100 fw-500">
                        Current Property
                      </span>
                    </h5>
                    Profitable (%): {comparable?.perc_profitable ? `${comparable?.perc_profitable.toFixed(1)}%` : NA}
                    <br />
                    Avg PSF (Last 3mths): {comparable?.avg_psf_last3mths ? `$${comparable?.avg_psf_last3mths.toFixed(2)}` : NA}
                  </p>
              </>
            }

            {!isProject(popupInfo) && isProperty(popupInfo)
              && <>
                <p className="px-10 text-dark-1 text-14 border-top-light">
                  <h5 className="text-16 py-10">Property Info</h5>
                  Distance: {popupInfo.distance.toFixed(0)}m
                  <br />
                  Profitable (%): {popupInfo.perc_profitable ? `${popupInfo.perc_profitable.toFixed(1)}%` : NA}
                  <br />
                  Avg PSF (Last 3mths): {popupInfo.avg_psf_last3mths ? `$${popupInfo.avg_psf_last3mths.toFixed(2)}` : NA}
                  <br />
                  Property Type{popupInfo.property_types.length > 1 ? 's' : ''}: {popupInfo.property_types.join(', ')}
                  {popupInfo.latest_completion_date
                    && <>
                      <br />
                      Latest Completion Date: {popupInfo.latest_completion_date}
                    </>
                  }
                </p>

                <div className="px-10 mt-20 mb-20">
                  <Link
                    className="button -sm -dark-1 bg-blue-1 text-white rounded-100 text-12 px-10 py-5"
                    // to={`/project/${encodeURIComponent(popupInfo.id)}`}
                    // target="_blank"
                    // rel="noopener noreferrer"
                    onClick={() => {
                      const chunks = popupInfo.id.split(',');
                      setPopupInfo(null);
                      closePopupPanel();
                      goToProperty(chunks[0], chunks[1]);
                    }
                  }>
                    Click here to view project details
                  </Link>
                </div>
              </>
            }

            {!isProject(popupInfo) && !isProperty(popupInfo) && !isBlockMarker(popupInfo)
              && <>
                <p className="px-10 text-dark-1 text-14 border-top-light mb-20">
                  <h5 className="text-16 py-10">Amenity Info</h5>
                  Distance: {popupInfo.distance.toFixed(0)}m
                  <br />
                  Type: {popupInfo.type}
                </p>
              </>
            }

            {isBlockMarker(popupInfo)
              && <>
                <p className="px-10 text-dark-1 text-14 border-top-light mb-20">
                  <h5 className="text-16 py-10">Block Info</h5>
                  Postal Code: {popupInfo.postal}
                </p>

                <div className="mt-20 mb-20 y-gap-10 px-10 proj-sch-list">
                  {popupInfo.schools.filter((val) => val.level === 'primary').length > 0
                    && <div className="d-flex items-center mt-10 border-bottom-light">
                      <img className="am-ic mr-10" src="/img/general/icons/am_sch_pri.png" />
                      <div className="text-16 fw-500">Nearby Primary Schools</div>
                    </div>
                  }
                  {popupInfo.schools.filter((val) => val.level === 'primary')?.map((val, i) => (
                    <div
                      className="col-12 border-top-light proj-sch-item"
                      key={i}
                      onClick={() => {
                        setShowSchoolLabel({
                          name: val.name,
                          lat: val.lat,
                          lng: val.lng
                        });
                        setRadiusMaskCoords({
                          longitude: val.lng,
                          latitude: val.lat
                        });

                        if (document.getElementById('mapSearchSidebar').classList.contains('show')) {
                          document.getElementById('mapsearch-close-button').click();
                        }
                        
                        flyTo([val.lng, val.lat]);
                      }}
                    >
                      <div className="row items-center justify-between">
                        <div className="col-9">
                          <div className="text-13 hover-blue">
                            {val.name}
                          </div>
                        </div>

                        <div className="col-3">
                          <div className="text-13 text-right">
                            {formatDistanceLabel(val.distance)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {popupInfo.schools.filter((val) => val.level === 'secondary').length > 0
                    && <div className="d-flex items-center mt-20 border-bottom-light">
                      <img className="am-ic mr-10" src="/img/general/icons/am_sch_sec.png" />
                      <div className="text-16 fw-500">Nearby Secondary Schools</div>
                    </div>
                  }
                  {popupInfo.schools.filter((val) => val.level === 'secondary')?.map((val, i) => (
                    <div
                      className="col-12 border-top-light proj-sch-item"
                      key={i}
                      onClick={() => {
                        setShowSchoolLabel({
                          name: val.name,
                          lat: val.lat,
                          lng: val.lng
                        });
                        setRadiusMaskCoords({
                          longitude: val.lng,
                          latitude: val.lat
                        });

                        if (document.getElementById('mapSearchSidebar').classList.contains('show')) {
                          document.getElementById('mapsearch-close-button').click();
                        }
                        
                        flyTo([val.lng, val.lat]);
                      }}
                    >
                      <div className="row items-center justify-between">
                        <div className="col-9">
                          <div className="text-13 hover-blue">
                            {val.name}
                          </div>
                        </div>

                        <div className="col-3">
                          <div className="text-13 text-right">
                            {formatDistanceLabel(val.distance)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            }
            
            {!isProject(popupInfo) && !isBlockMarker(popupInfo)
              && <>
                <p className="px-10 pt-10 text-blue-1 text-14 border-top-light">
                  <h5 className="text-16 text-dark-1 pb-20">Map Navigation</h5>
                  <a
                    className="button -sm -dark-1 bg-blue-1 text-white rounded-100 text-12 px-10 py-5"
                    href={popupInfo.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="icon-route text-15 mr-10"
                      title="Open Google Map"
                    />
                    View in Google Map
                  </a>
                  <a
                    className="button -sm -dark-1 bg-blue-1 text-white rounded-100 text-12 px-10 py-5 mt-10"
                    href={`https://www.google.com/maps/dir/?api=1&origin=${resultPropLocation?.lat},${resultPropLocation?.lng}&destination=${popupInfo?.lat},${popupInfo?.lng}&travelmode=walking`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="icon-pedestrian text-15 mr-10"
                      title="Navigate by walking"
                    />
                    Navigate by Walking
                  </a>
                  <a
                    className="button -sm -dark-1 bg-blue-1 text-white rounded-100 text-12 px-10 py-5 mt-10"
                    href={`https://www.google.com/maps/dir/?api=1&origin=${resultPropLocation?.lat},${resultPropLocation?.lng}&destination=${popupInfo?.lat},${popupInfo?.lng}&travelmode=driving`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="icon-car text-15 mr-10"
                      title="Navigate by driving"
                    />
                    Navigate by Driving
                  </a>
                </p>
              </>
            }
          </div>
        }

      </div>

      <div
        className={`offcanvas offcanvas-start filter-bar-nopad ${noSelectClass(user)}`}
        tabIndex="-1"
        id="mapSearchSidebar"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasLabel">
            Nearby School Search
          </h5>
          <button
            id="mapsearch-close-button"
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="px-10 watermark h-100">
          <div className="sidebar__item -no-border">
            <div className="px-20 py-20 bg-light-2 rounded-4">
              <h5 className="text-16 fw-500 mb-10">Search School</h5>
              {/* <img className="omattr" src="/img/general/onemap.png" alt="OneMap" /> */}
              <div className="row y-gap-20 py-10 px-10">
                <SchoolQueryFilter
                  onSearchLocation={onSchoolSearchLocation}
                  schoolSearchValue={schoolSearchValue}
                  setSchoolSearchValue={setSchoolSearchValue}
                />
              </div>
            </div>
          </div>

          <div className="mt-20 mb-20 y-gap-10 px-10 proj-sch-list">
            {schools.origin?.filter((val) => val.level === 'primary')?.length > 0
              && <div className="d-flex items-center mt-10 border-bottom-light">
                <img className="am-ic mr-10" src="/img/general/icons/am_sch_pri.png" />
                <div className="text-16 fw-500">Nearby Primary Schools</div>
              </div>
            }
            {schools.origin?.filter((val) => val.level === 'primary')?.map((val, i) => (
              <div
                className="col-12 border-top-light proj-sch-item"
                key={i}
                onClick={() => {
                  setShowSchoolLabel({
                    name: val.name,
                    lat: val.lat,
                    lng: val.lng
                  });
                  setRadiusMaskCoords({
                    longitude: val.lng,
                    latitude: val.lat
                  });

                  if (document.getElementById('mapSearchSidebar').classList.contains('show')) {
                    document.getElementById('mapsearch-close-button').click();
                  }
                  
                  flyTo([val.lng, val.lat]);
                }}
              >
                <div className="row items-center justify-between">
                  <div className="col-9">
                    <div className="text-13 hover-blue">
                      {val.name}
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="text-13 text-right">
                      {formatDistanceLabel(val.distance)}
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {schools.origin?.filter((val) => val.level === 'secondary')?.length > 0
              && <div className="d-flex items-center mt-20 border-bottom-light">
                <img className="am-ic mr-10" src="/img/general/icons/am_sch_sec.png" />
                <div className="text-16 fw-500">Nearby Secondary Schools</div>
              </div>
            }
            {schools.origin?.filter((val) => val.level === 'secondary')?.map((val, i) => (
              <div
                className="col-12 border-top-light proj-sch-item"
                key={i}
                onClick={() => {
                  setShowSchoolLabel({
                    name: val.name,
                    lat: val.lat,
                    lng: val.lng
                  });
                  setRadiusMaskCoords({
                    longitude: val.lng,
                    latitude: val.lat
                  });

                  if (document.getElementById('mapSearchSidebar').classList.contains('show')) {
                    document.getElementById('mapsearch-close-button').click();
                  }
                  
                  flyTo([val.lng, val.lat]);
                }}
              >
                <div className="row items-center justify-between">
                  <div className="col-9">
                    <div className="text-13 hover-blue">
                      {val.name}
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="text-13 text-right">
                      {formatDistanceLabel(val.distance)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="proj-map-bars noselect">
        <MapModeSelect
          theme={mapStyle}
          setTheme={setMapStyle}
          setTimeDistanceMode={setTimeDistanceMode}
          displayAmenities={displayAmenities}
          setDisplayAmenities={setDisplayAmenities}
        />
      </div>

      <div
        className="proj-map-set noselect d-flex justify-content-center"
        style={{
          width: mapMediaStyle?.width,
          ...(
            checkMediaQuery() ? {
              bottom: isMinimized ? '66px' : '49svh;'
            } : {}
          )
        }}
      >
        {(highlightList?.length > 0 || blockMarker) && !isMaximized
          && <button
            className="p-2 button -sm -dark-1 bg-white text-blue-1 border-light rounded-100 text-12 px-10 py-5"
            onClick={() => {
              setHighlightList([]);
              clearBlockMarker();
            }}
          >
            Clear Selection
            <i className="icon-close ml-5 text-10" />
          </button>
        }
      </div>

      <div
        className={`offcanvas offcanvas-start filter-bar-nopad ${noSelectClass(user)}`}
        tabIndex="-1"
        id="areaSidebar"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasLabel">
            {areaSelected?.id}
          </h5>
          <button
            id="area-close-button"
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="watermark h-100">
          <div className={`data-table vscroll px-10 pb-10 ${noSelectClass(user)}`}>
            <AreaTable
              data={areaSelected?.properties ?? []}
              goToProperty={(project, street) => {
                goToProperty(project, street);
                if (document.getElementById('areaSidebar').classList.contains('show')) {
                  document.getElementById('area-close-button').click();
                }
              }}
            />
          </div>
        </div>
      </div>

      {err
        && <div className="map-err px-20"
          style={{
            ...mapMediaStyle,
            height: '65px'
          }}
        >
          <div className="alert alert-danger" role="alert">
            {err}
          </div>
        </div>
      }
    </>
  );
};

export default DetailMap;
