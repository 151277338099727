import { noSelectClass } from "@/utils/user";
import Surroundings from "@/components/project/Surroundings";
import ReviewProgress from "@/components/project/Review";
import CompareTable from "@/components/project/CompareTable";
import { useState } from "react";
import NearbySchools from "./NearbySchools";

const NEARBY_OPTIONS = [
  { id: 'schools', label: 'Nearby Schools '},
  { id: 'amenities', label: 'Nearby Amenities' },
  { id: 'properties', label: 'Nearby Similar Properties' },
];

const NearbyTab = ({
  user,
  id,
  schools,
  resultAllLocations,
  nearbyProperties,
  comparable,
  onClickPlace,
  highlightList,
  clearHighlightList,
  isMaximized,
  blockMarker,
  onBlockSelect,
  clearBlockSelect,
  onSchoolSelect,
  goToProperty
}) => {
  const [display, setDisplay] = useState(NEARBY_OPTIONS[0].id);

  return (
    <>
      <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector px-0 noselect">
        <div
          className="dropdown__button dropdown__button_sm d-flex items-center bg-white border-light rounded-100 px-15 py-5 text-12 lh-12"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          data-bs-offset="0,10"
        >
          <span className="js-dropdown-nearby-opt">{NEARBY_OPTIONS.find(o => o.id === display).label}</span>
          <i className="icon icon-chevron-sm-down text-7 ml-10" />
        </div>
        <div className="toggle-element -dropdown dropdown-menu dropdown-top">
          <div className="text-12 y-gap-20 js-dropdown-list dropdown-top">
            {NEARBY_OPTIONS.map((option, index) => (
              <div key={index}>
                <button
                  className={`d-block js-dropdown-link ${
                    display === option.id ? "text-blue-1 " : ""
                  }`}
                  onClick={() => {
                    setDisplay(option.id);
                    clearHighlightList();
                    clearBlockSelect();
                    document.querySelector(".js-dropdown-nearby-opt").textContent = option.label;
                  }}
                >
                  {option.label}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {display === 'amenities'
        && <section className="px-5">
          <div className={`${noSelectClass(user)}`}>
            <div className="row x-gap-30 y-gap-30 pt-20 mb-50">
              <Surroundings
                schools={schools}
                locations={resultAllLocations}
                onClickPlace={onClickPlace}
                highlightList={highlightList}
              />
            </div>
          </div>
        </section>
      }

      {display === 'schools'
        && <section className="px-5">
          <div className={`${noSelectClass(user)}`}>
            <NearbySchools
              schools={schools}
              blockMarker={blockMarker}
              onSelect={onBlockSelect}
              highlightList={highlightList}
              isMaximized={isMaximized}
              onSchoolSelect={onSchoolSelect}
            />
          </div>
        </section>
      }

      {display === 'properties'
        && <section className="modal-table">
          <div className={`data-table ${noSelectClass(user)}`}>

            {comparable
              && <ReviewProgress
                comparable={comparable}
              />
            }

            <CompareTable
              property={{
                id,
                distance: 0,
                avg_psf_last3mths: comparable.avg_psf_last3mths,
                perc_profitable: comparable.perc_profitable,
                property_types: comparable.property_types?.split(',').filter(v => !!v),
                perc_HDB_buyer: comparable.perc_HDB_buyer,
                latest_completion_date: comparable.latest_completion_date,
                tenure_available: comparable.tenure_available,
                total_units: comparable.total_units,
                perc_sold_at_launch: comparable.perc_sold_at_launch,
              }}
              data={nearbyProperties}
              goToProperty={goToProperty}
            />
          </div>
        </section>
      }
    </>
  );
};

export default NearbyTab;