import {
  LOCATION_BLOCK,
  LOCATION_MARKER,
  LOCATION_PROPERTY,
  LOCATION_SCHOOL,
  MARKET_SEGMENTS,
  MARKET_SEGMENT_ID_MAP,
  PLANNING_AREAS,
  REGIONS,
  convertIntToPropertyType,
  convertIntToSaleType,
  convertIntToTenureType,
  expandId
} from "./areas";
import {
  FILTER_AVG_PRICE,
  FILTER_AVG_UNIT_PRICE,
  FILTER_COMPLETION_DATE,
  FILTER_HDB_BUYERS,
  FILTER_LAUNCH_PERC,
  FILTER_LOCATION,
  FILTER_MAP_TRANSACTION_DATE,
  FILTER_PROFITABLE,
  FILTER_PROPERTY_AGE,
  FILTER_PROPERTY_TYPE,
  FILTER_PSF_RENTAL_6M,
  FILTER_SIZE,
  FILTER_TENURE,
  FILTER_TOTAL_UNITS
} from "@/utils/filter";
import { deepCopy, getFloorFromAddress } from "./convert";
import { capitalizeAll } from "./text";
import { property, uniq } from "lodash";

export const MODE_DEFAULT_MAP = 'default';
export const MODE_UPCOMING = 'upcoming';

export const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

export const HOME_ICON = "M13.22,2.984c-1.125,0-2.504,0.377-3.53,1.182C8.756,3.441,7.502,2.984,6.28,2.984c-2.6,0-4.714,2.116-4.714,4.716c0,0.32,0.032,0.644,0.098,0.96c0.799,4.202,6.781,7.792,7.46,8.188c0.193,0.111,0.41,0.168,0.627,0.168c0.187,0,0.376-0.041,0.55-0.127c0.011-0.006,1.349-0.689,2.91-1.865c0.021-0.016,0.043-0.031,0.061-0.043c0.021-0.016,0.045-0.033,0.064-0.053c3.012-2.309,4.6-4.805,4.6-7.229C17.935,5.1,15.819,2.984,13.22,2.984z M12.544,13.966c-0.004,0.004-0.018,0.014-0.021,0.018s-0.018,0.012-0.023,0.016c-1.423,1.076-2.674,1.734-2.749,1.771c0,0-6.146-3.576-6.866-7.363C2.837,8.178,2.811,7.942,2.811,7.7c0-1.917,1.554-3.47,3.469-3.47c1.302,0,2.836,0.736,3.431,1.794c0.577-1.121,2.161-1.794,3.509-1.794c1.914,0,3.469,1.553,3.469,3.47C16.688,10.249,14.474,12.495,12.544,13.966z";
export const SCHOOL_ICON = "M8.627,7.885C8.499,8.388,7.873,8.101,8.13,8.177L4.12,7.143c-0.218-0.057-0.351-0.28-0.293-0.498c0.057-0.218,0.279-0.351,0.497-0.294l4.011,1.037C8.552,7.444,8.685,7.667,8.627,7.885 M8.334,10.123L4.323,9.086C4.105,9.031,3.883,9.162,3.826,9.38C3.769,9.598,3.901,9.82,4.12,9.877l4.01,1.037c-0.262-0.062,0.373,0.192,0.497-0.294C8.685,10.401,8.552,10.18,8.334,10.123 M7.131,12.507L4.323,11.78c-0.218-0.057-0.44,0.076-0.497,0.295c-0.057,0.218,0.075,0.439,0.293,0.495l2.809,0.726c-0.265-0.062,0.37,0.193,0.495-0.293C7.48,12.784,7.35,12.562,7.131,12.507M18.159,3.677v10.701c0,0.186-0.126,0.348-0.306,0.393l-7.755,1.948c-0.07,0.016-0.134,0.016-0.204,0l-7.748-1.948c-0.179-0.045-0.306-0.207-0.306-0.393V3.677c0-0.267,0.249-0.461,0.509-0.396l7.646,1.921l7.654-1.921C17.91,3.216,18.159,3.41,18.159,3.677 M9.589,5.939L2.656,4.203v9.857l6.933,1.737V5.939z M17.344,4.203l-6.939,1.736v9.859l6.939-1.737V4.203z M16.168,6.645c-0.058-0.218-0.279-0.351-0.498-0.294l-4.011,1.037c-0.218,0.057-0.351,0.28-0.293,0.498c0.128,0.503,0.755,0.216,0.498,0.292l4.009-1.034C16.092,7.085,16.225,6.863,16.168,6.645 M16.168,9.38c-0.058-0.218-0.279-0.349-0.498-0.294l-4.011,1.036c-0.218,0.057-0.351,0.279-0.293,0.498c0.124,0.486,0.759,0.232,0.498,0.294l4.009-1.037C16.092,9.82,16.225,9.598,16.168,9.38 M14.963,12.385c-0.055-0.219-0.276-0.35-0.495-0.294l-2.809,0.726c-0.218,0.056-0.351,0.279-0.293,0.496c0.127,0.506,0.755,0.218,0.498,0.293l2.807-0.723C14.89,12.825,15.021,12.603,14.963,12.385";
export const SHOP_ICON = "M17.671,13.945l0.003,0.002l1.708-7.687l-0.008-0.002c0.008-0.033,0.021-0.065,0.021-0.102c0-0.236-0.191-0.428-0.427-0.428H5.276L4.67,3.472L4.665,3.473c-0.053-0.175-0.21-0.306-0.403-0.306H1.032c-0.236,0-0.427,0.191-0.427,0.427c0,0.236,0.191,0.428,0.427,0.428h2.902l2.667,9.945l0,0c0.037,0.119,0.125,0.217,0.239,0.268c-0.16,0.26-0.257,0.562-0.257,0.891c0,0.943,0.765,1.707,1.708,1.707S10,16.068,10,15.125c0-0.312-0.09-0.602-0.237-0.855h4.744c-0.146,0.254-0.237,0.543-0.237,0.855c0,0.943,0.766,1.707,1.708,1.707c0.944,0,1.709-0.764,1.709-1.707c0-0.328-0.097-0.631-0.257-0.891C17.55,14.182,17.639,14.074,17.671,13.945 M15.934,6.583h2.502l-0.38,1.709h-2.312L15.934,6.583zM5.505,6.583h2.832l0.189,1.709H5.963L5.505,6.583z M6.65,10.854L6.192,9.146h2.429l0.19,1.708H6.65z M6.879,11.707h2.027l0.189,1.709H7.338L6.879,11.707z M8.292,15.979c-0.472,0-0.854-0.383-0.854-0.854c0-0.473,0.382-0.855,0.854-0.855s0.854,0.383,0.854,0.855C9.146,15.596,8.763,15.979,8.292,15.979 M11.708,13.416H9.955l-0.189-1.709h1.943V13.416z M11.708,10.854H9.67L9.48,9.146h2.228V10.854z M11.708,8.292H9.386l-0.19-1.709h2.512V8.292z M14.315,13.416h-1.753v-1.709h1.942L14.315,13.416zM14.6,10.854h-2.037V9.146h2.227L14.6,10.854z M14.884,8.292h-2.321V6.583h2.512L14.884,8.292z M15.978,15.979c-0.471,0-0.854-0.383-0.854-0.854c0-0.473,0.383-0.855,0.854-0.855c0.473,0,0.854,0.383,0.854,0.855C16.832,15.596,16.45,15.979,15.978,15.979 M16.917,13.416h-1.743l0.189-1.709h1.934L16.917,13.416z M15.458,10.854l0.19-1.708h2.218l-0.38,1.708H15.458z";
export const SPORT_ICON = "M23.76 9.58A11.99 11.99 0 0 0 0 12a12.08 12.08 0 0 0 3.51 8.49 12.12 12.12 0 0 0 6.07 3.27A11.99 11.99 0 0 0 24 12a12 12 0 0 0-.24-2.42zm-1.51 2.32c-.15-.03-3.62-.78-7.14-.34a38.64 38.64 0 0 0-.9-2.01c4.04-1.66 5.69-4.03 5.7-4.06a10.2 10.2 0 0 1 2.34 6.4zm-3.48-7.6c-.03.05-1.49 2.27-5.35 3.72a52.06 52.06 0 0 0-3.83-5.98 10.23 10.23 0 0 1 9.18 2.27zM7.63 2.74a61.6 61.6 0 0 1 3.8 5.9A37.91 37.91 0 0 1 1.97 9.9c.67-3.18 2.8-5.8 5.66-7.16zM1.75 12l.01-.32c.18 0 5.25.11 10.52-1.46.3.57.58 1.15.83 1.74l-.4.12c-5.53 1.79-8.34 6.76-8.34 6.76A10.21 10.21 0 0 1 1.76 12zM12 22.25a10.2 10.2 0 0 1-6.53-2.35l.23.18s1.97-4.29 8.04-6.4l.07-.02a42.64 42.64 0 0 1 2.2 7.78 10.2 10.2 0 0 1-4.01.8zm5.73-1.75c-.1-.62-.65-3.63-2-7.32 3.31-.53 6.18.38 6.39.45a10.26 10.26 0 0 1-4.4 6.87z";
export const TRANSPORT_ICON = "M12 2c-4 0-8 .5-8 4v9.5C4 17.43 5.57 19 7.5 19l-1.15 1.15c-.31.31-.09.85.36.85H7.8c.13 0 .26-.05.35-.15L10 19h4l1.85 1.85c.09.09.22.15.35.15h1.09c.45 0 .67-.54.35-.85L16.5 19c1.93 0 3.5-1.57 3.5-3.5V6c0-3.5-4-4-8-4zM7.5 17c-.83 0-1.5-.67-1.5-1.5S6.67 14 7.5 14s1.5.67 1.5 1.5S8.33 17 7.5 17zm3.5-7H6V6h5v4zm5.5 7c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm1.5-7h-5V6h5v4z";
export const ATTRACTION_ICON = "M10,6.536c-2.263,0-4.099,1.836-4.099,4.098S7.737,14.732,10,14.732s4.099-1.836,4.099-4.098S12.263,6.536,10,6.536M10,13.871c-1.784,0-3.235-1.453-3.235-3.237S8.216,7.399,10,7.399c1.784,0,3.235,1.452,3.235,3.235S11.784,13.871,10,13.871M17.118,5.672l-3.237,0.014L12.52,3.697c-0.082-0.105-0.209-0.168-0.343-0.168H7.824c-0.134,0-0.261,0.062-0.343,0.168L6.12,5.686H2.882c-0.951,0-1.726,0.748-1.726,1.699v7.362c0,0.951,0.774,1.725,1.726,1.725h14.236c0.951,0,1.726-0.773,1.726-1.725V7.195C18.844,6.244,18.069,5.672,17.118,5.672 M17.98,14.746c0,0.477-0.386,0.861-0.862,0.861H2.882c-0.477,0-0.863-0.385-0.863-0.861V7.384c0-0.477,0.386-0.85,0.863-0.85l3.451,0.014c0.134,0,0.261-0.062,0.343-0.168l1.361-1.989h3.926l1.361,1.989c0.082,0.105,0.209,0.168,0.343,0.168l3.451-0.014c0.477,0,0.862,0.184,0.862,0.661V14.746z";
export const PROPERTY_ICON = "M18.121,9.88l-7.832-7.836c-0.155-0.158-0.428-0.155-0.584,0L1.842,9.913c-0.262,0.263-0.073,0.705,0.292,0.705h2.069v7.042c0,0.227,0.187,0.414,0.414,0.414h3.725c0.228,0,0.414-0.188,0.414-0.414v-3.313h2.483v3.313c0,0.227,0.187,0.414,0.413,0.414h3.726c0.229,0,0.414-0.188,0.414-0.414v-7.042h2.068h0.004C18.331,10.617,18.389,10.146,18.121,9.88 M14.963,17.245h-2.896v-3.313c0-0.229-0.186-0.415-0.414-0.415H8.342c-0.228,0-0.414,0.187-0.414,0.415v3.313H5.032v-6.628h9.931V17.245z M3.133,9.79l6.864-6.868l6.867,6.868H3.133z";

export const pinStyle = {
  cursor: 'pointer',
  fill: '#3554d1',
  stroke: 'none',
};

export const homePinStyle = {
  ...pinStyle,
  fill: '#DE3163',
};

export const blockPinStyle = {
  ...pinStyle,
  fill: '#0d2857'
};

export const radiusMaskStyle = (id) => ({
  id: `circle-mask-${id}`,
  type: 'fill',
  paint: {
    'fill-color': 'rgba(0, 0, 0, 0.1)',
  }
});

export const radiusLabelStyle = (id) => ({
  id: `circle-label-${id}`,
  type: 'symbol',
  layout: {
    'text-field': ['get', 'label'],
    'text-size': 16,
    'text-offset': [0, 1.5],
    'text-anchor': 'bottom',
  },
  paint: {
    'text-color': 'rgba(0, 0, 0, 0.8)',
  }
});

export const getMarkerIcon = (marker) => {
  if (marker.type === 'Schools') return SCHOOL_ICON;
  if (marker.type === 'Shopping') return SHOP_ICON;
  if (marker.type === 'Sports') return SPORT_ICON;
  if (marker.type === 'Transport') return TRANSPORT_ICON;
  if (marker.type === 'Property') return PROPERTY_ICON;
  return ATTRACTION_ICON;
};

export const getMarkerScale = (marker) => {
  if (marker.type === 'Schools') return 'scale(0.4),translate(20,7)';
  if (marker.type === 'Sports') return 'scale(0.25),translate(35,14)';
  if (marker.type === 'Transport') return 'scale(0.32),translate(25,10)';
  if (marker.type === 'Property') return 'scale(0.4),translate(20,6)';
  return 'scale(0.4),translate(20,8)';
};

export const getMarkerPriColor = (marker) => {
  if (marker.type === 'Schools') return '#008009';
  if (marker.type === 'Sports') return '#7e53f9';
  if (marker.type === 'Transport') return '#927238';
  if (marker.type === 'Property') return '#DE3163';
  return '#003399';
};

export const getMarkerStyle = (marker) => {
  const style = deepCopy(pinStyle);
  if (marker.type === 'Schools') style.fill = '#008009';
  if (marker.type === 'Sports') style.fill = '#7e53f9';
  if (marker.type === 'Transport') style.fill = '#927238';
  if (marker.type === 'Property') style.fill = '#DE3163';
  return style;
};

export const generateLineData = (location1, location2) => ({
  type: 'Feature',
  geometry: {
    type: 'LineString',
    coordinates: [
      [location1.lng, location1.lat],
      [location2.lng, location2.lat]
    ]
  }
});

export const generateLinesData = (property, locations) => ({
  type: 'FeatureCollection',
  features: locations.map(location => generateLineData(property, location))
});

export const generateMultipleLinesData = (properties, prop) => {
  const data = [];
  properties.forEach(property => {
    property[prop]?.forEach(p => data.push(generateLineData(property, p)))
  });
  return {
    type: 'FeatureCollection',
    features: data
  };
};

export const generateLineStyle = (marker) => {
  const color = getMarkerPriColor(marker);
  return {
    id: 'route',
    type: 'line',
    paint: {
      'line-color': color,
      'line-width': 2,
      'line-dasharray': [2, 2],
    }
  };
};

export const MAP_FILTER_OPTIONS = [
  FILTER_COMPLETION_DATE,
  FILTER_MAP_TRANSACTION_DATE,
  FILTER_PROPERTY_TYPE,
  FILTER_TENURE,
  FILTER_PROPERTY_AGE,
  FILTER_PROFITABLE,
  FILTER_AVG_PRICE,
  FILTER_AVG_UNIT_PRICE,
  FILTER_SIZE,
  FILTER_HDB_BUYERS,
  FILTER_TOTAL_UNITS,
  FILTER_LAUNCH_PERC,
  FILTER_PSF_RENTAL_6M,
  FILTER_LOCATION
];

export const getPropertyMarkerColor = (data, field) => {
  if (field === 'avgPsf3m') {
    if (data.avgPsf3m === null || data.avgPsf3m < 0 || data.fairPsf === null || data.fairPsf < 0) return '#34495e';
    if (data.fairPsf < data.avgPsf3m) return '#e74c3c';
    return '#1abc9c';
  }
  if (field === 'profitable') {
    if (data[field] === null) return '#34495e';
    if (data[field] > 90) return '#e74c3c';
    if (data[field] > 70) return '#e67e22';
    if (data[field] > 50) return '#f1c40f';
    return '#3498db';
  }
  return '#DE3163';
};

export const getProfitableTextClass = (value) => {
  if (value === null || value < 0) return 'map-marker-color-grey';
  if (value > 90) return 'map-marker-color-red';
  if (value > 70) return 'map-marker-color-orange';
  if (value > 50) return 'map-marker-color-yellow';
  return 'map-marker-color-blue';
};

export const getPropertyTextColor = (data, field) => {
  if (field === 'fairPsf') {
    if (data.fairPsf === null || data.fairPsf < 0 || data.avgPsf3m === null || data.avgPsf3m < 0) return 'map-marker-color-grey';
    if (data.fairPsf < data.avgPsf3m) return 'map-marker-color-red';
    return 'map-marker-color-blue';
  }
  if (field === 'profitable') {
    if (data[field] === null || data[field] < 0) return 'map-marker-color-grey';
    if (data[field] > 90) return 'map-marker-color-red';
    if (data[field] > 70) return 'map-marker-color-orange';
    if (data[field] > 50) return 'map-marker-color-yellow';
    return 'map-marker-color-blue';
  }
  return 'map-marker-color-grey';
};

export const getPropertyBgColor = (data, field) => {
  if (field === 'fairPsf') {
    if (data[field] === null || data.avgPsf3m === null) return 'map-marker-bg-grey';
    if (data.avgPsf3m > data[field]) return 'map-marker-bg-red';
    return 'map-marker-bg-blue';
  }
  if (field === 'profitable') {
    if (data[field] === null || data[field] < 0) return 'map-marker-bg-grey';
    if (data[field] > 90) return 'map-marker-bg-red';
    if (data[field] > 70) return 'map-marker-bg-orange';
    if (data[field] > 50) return 'map-marker-bg-yellow';
    return 'map-marker-bg-blue';
  }
  return 'map-marker-bg-grey';
};

export const getPropertyFileName = (str) => {
  // Replace all non-alphanumeric characters with underscores
  str = str.replace(/\W+/g, '_');
  // Replace multiple underscores with a single underscore
  str = str.replace(/_+/g, '_');
  // Strip leading and trailing underscores
  str = str.replace(/^_+|_+$/g, '');
  return str.toLowerCase();
};

const convertMapStr = (s) => {
  if (!s || s.length === 0) return {};
  const data = {};
  s.split('&').forEach(v => {
    const c = v.split(':');
    data[c[0]] = float(c[1]);
  });
  return data;
};

export const decompressBasicMapData = (txt) => txt.split('\n')
  .map(l => l.split('|'))
  .map(l => ({
    marker: expandId(l[0]),
    lat: parseFloat(l[1]),
    lng: parseFloat(l[2]),
    district: l[3],
    sector: l[4],
    area: REGIONS[parseInt(l[5].split(',')[0])]['areas'][parseInt(l[5].split(',')[1])],
    marketSegment: MARKET_SEGMENT_ID_MAP[MARKET_SEGMENTS[parseInt(l[6])].label],
    types: convertIntToPropertyType(parseInt(l[7])),
    completion: l[8],
    profitable: l[9] || l[9] === '-1' ? parseFloat(l[9]) : null,
    totalTx: l[10] ? parseInt(l[10]) : null,
    perc6ProfitTx: l[11] ? parseInt(l[11]) : null,
    tenures: convertIntToTenureType(l[12]),
    project: expandId(l[13]),
    projectId: l[14],
    // avgPsf3m: convertMapStr(l[15]),
    // fairPsf: convertMapStr(l[16]),
    type: LOCATION_PROPERTY
  }));

const concatUnique = (arr, adds) => {
  const newArr = [...arr, ...adds]
  const unique = new Set(newArr);
  return [...unique];
};

export const groupMapData = (data) => {
  const markers = [];
  const markerIdx = {};
  for (let i = 0; i < data.length; i++) {
    let property = data[i];
    if (!(property.marker in markerIdx)) {
      markerIdx[property.marker] = markers.length;
      markers.push({
        name: property.marker,
        projects: [property],
        lat: property.lat,
        lng: property.lng,
        district: property.district,
        sector: property.sector,
        area: property.area,
        marketSegment: property.marketSegment,
        types: property.types,
        profitable: [property.profitable],
        totalTx: property.totalTx,
        type: LOCATION_MARKER
      });
    } else {
      const idx = markerIdx[property.marker];
      markers[idx].projects.push(property);
      markers[idx].types = concatUnique(markers[idx].types, property.types);
      markers[idx].profitable = concatUnique(markers[idx].profitable, [property.profitable]);
      markers[idx].totalTx += property.totalTx ? property.totalTx : 0;
    }
  }
  for (let i = 0; i < markers.length; i++) {
    const profitables = markers[i].profitable.filter(p => p !== null && p >= 0);
    markers[i].profitable = profitables.length > 0 ? (profitables.reduce((s, v) => s + v, 0) / profitables.length) : null;
  }
  return markers;
};

export const decompressAdvMapData = (txt) => txt.split('\n')
  .map(l => l.split('|'))
  .map(l => ({
    lastTxDate: l[0],
    minPrice: l[1] ? parseFloat(l[1]) : null,
    maxPrice: l[2] ? parseFloat(l[2]) : null,
    minSize: l[3] ? parseFloat(l[3]) : null,
    maxSize: l[4] ? parseFloat(l[4]) : null,
    hdbBuyers: l[5] ? parseFloat(l[5]) : null,
    totalUnits: l[6] ? parseInt(l[6]) : null,
    soldAtLaunch: l[7] ? parseFloat(l[7]) : null,
    last6mAvgRentPsf: l[8] ? parseFloat(l[8]) : null,
    minRent: l[9] ? parseFloat(l[9]) : null,
    maxRent: l[10] ? parseFloat(l[10]) : null,
    tenureDate: l[11]
  }));

export const decompressUpcomingMapData = (txt) => txt.split('\n')
  .map(l => l.split('|'))
  .map(l => ({
    name: expandId(l[0]),
    street: expandId(l[1]),
    types: convertIntToPropertyType(parseInt(l[2])),
    fairPsf: l[3] ? parseFloat(l[3]) : null,
    lat: parseFloat(l[4].split(',')[0]),
    lng: parseFloat(l[4].split(',')[1]),
    last3mAvgPsf: l[5] ? parseFloat(l[5]) : null,
    lastTxDate: l[6],
    tenures: convertIntToTenureType(l[7]),
    tenureYears: l[8] ? l[8].split(',') : [],
    tenureDate: l[9],
    completion: l[10],
    profitable: l[11] ? parseFloat(l[11]) : null,
    comps: l.slice(12).map(c => c.split(',')).map(c => ({
      name: expandId(c[0]),
      street: expandId(c[1]),
      distance: c[2] ? parseFloat(c[2]) : null,
      lat: parseFloat(c[3]),
      lng: parseFloat(c[4]),
      last3mAvgPsf: c[5] ? parseFloat(c[5]) : null,
      profitable: c[6] ? parseFloat(c[6]) : null
    }))
  }));

export const groupPropertyByArea = (mapData) => {
  return PLANNING_AREAS.map(a => ({
    id: a.name.en,
    lat: a.coordinates.latitude,
    lng: a.coordinates.longitude,
    properties: mapData?.filter(p => p.area === a.name.en)?.sort((a, b) => b.profitable - a.profitable),
  }))
    .filter(a => a.properties.length > 0)
    .map(a => {
      const props = a.properties.filter(p => p.profitable !== null && p.profitable >= 0);
      const avgPsfProps = a.properties.filter(p => !!p.avgPsf3m && p.avgPsf3m > 0);
      const fairPsfProps = a.properties.filter(p => !!p.fairPsf && p.fairPsf > 0)
      return {
        ...a,
        profitable: props.length > 0 ? props.reduce((s, x) => s + x.profitable, 0) / props.length : null,
        avgPsf3m: avgPsfProps.length > 0 ? avgPsfProps.reduce((s, x) => s + x.avgPsf3m, 0) / avgPsfProps.length : null,
        fairPsf: fairPsfProps.length > 0 ? fairPsfProps.reduce((s, x) => s + x.fairPsf, 0) / fairPsfProps.length : null,
        totalTx: props.reduce((s, x) => s + (x.totalTx ?? 0), 0)
      };
    });
};

const getGalleryLinks = (projectFolderName, lastIdx) => lastIdx.length > 0
  ? Array.from(Array(lastIdx[0] + 1).keys()).map(i => ({
    url: `https://realsmart.global.ssl.fastly.net/i/${projectFolderName}/${i}.jpg`,
    fullsize: `https://realsmart.global.ssl.fastly.net/i/${projectFolderName}/${i + 10000}.jpg`
  }))
  : [];

export const decompressBasicPropertyData = (txt) => {
  const l = txt.split('|');
  return {
    name: expandId(l[0]),
    minSize: parseFloat(l[1]),
    maxSize: parseFloat(l[2]),
    district: l[3],
    sector: l[4],
    region: l[5],
    area: REGIONS[parseInt(l[6].split(',')[0])]['areas'][parseInt(l[6].split(',')[1])],
    marketSegment: MARKET_SEGMENT_ID_MAP[MARKET_SEGMENTS[parseInt(l[7])].label],
    totalTx: l[8] ? parseInt(l[8]) : null,
    hdbBuyers: l[9] ? parseFloat(l[9]) : null,
    totalTxAmt: l[10],
    lastTxDate: l[11],
    completion: l[12],
    tenureDate: l[13],
    totalUnits: l[14] ? parseInt(l[14]) : null,
    unsoldUnits: l[15] ? parseInt(l[15]) : null,
    soldAtLaunch: l[16] ? parseFloat(l[16]) : null,
    totalProfits: l[17] ? parseFloat(l[17]) : null,
    profitTx: l[18] ? parseInt(l[18]) : null,
    unprofitTx: l[19] ? parseInt(l[19]) : null,
    abortedTx: l[20] ? parseInt(l[20]) : null,
    perc6ProfitTx: l[21] ? parseInt(l[21]) : null,
    profitable: l[22] ? parseFloat(l[22]) : null,
    last6mAvgRentPsf: l[23] ? parseFloat(l[23]) : null,
    last6mTotalRent: l[24] ? parseInt(l[24]) : null,
    last1mAvgRentPsf: l[25] ? parseFloat(l[25]) : null,
    last1mTotalRent: l[26] ? parseInt(l[26]) : null,
    lat: parseFloat(l[27]),
    lng: parseFloat(l[28]),
    types: convertIntToPropertyType(parseInt(l[29])),
    streets: expandId(l[30]).split(','),
    tenures: convertIntToTenureType(l[31]),
    tenureYears: l[32] ? l[32].split(',') : [],
    developer: l[33],
    score: l[34] ? parseFloat(l[34]) : null,
    last1yReturns: l[35] ? parseFloat(l[35]) : null,
    last1yProfitTx: l[36] ? parseInt(l[36]) : null,
    // gallery: getGalleryLinks('parc_clematis', [19])
    // gallery: getGalleryLinks(getPropertyFileName(expandId(l[0])), l[37] ? l[37].split(',') : [])
    gallery: [] // TODO
  };
};

export const decompressAdvPropertyData = (streets, txt) => {
  const l = txt.split('\n');
  const s = {};
  streets.forEach((st, i) => s[`%${i}%`] = st);
  const decompressStreet = (addr) => {
    for (let i = 0; i < Object.keys(s).length; i++) {
      const key = Object.keys(s)[i];
      addr = addr.replace(key, s[key]);
    }
    return addr;
  };
  return {
    fairPsf: l[0] ? parseFloat(l[0]) : null,
    transactions: l[1] ? l[1].split('|').map(t => t.split(',')).map(t => ({
      saleDate: t[0],
      address: expandId(decompressStreet(t[1])),
      type: convertIntToSaleType(parseInt(t[2])),
      price: parseFloat(t[3]),
      unitPrice: parseFloat(t[4]),
      area: parseFloat(t[5]),
      areaType: t[6],
      purchaserAddrIndicator: t[7],
      postal: t[8],
      propertyType: convertIntToPropertyType(parseInt(t[9])),
      floor: getFloorFromAddress(expandId(decompressStreet(t[1])))
    })) : [],
    profits: l[2] ? l[2].split('|').map(t => t.split(',')).map(t => ({
      saleDate: t[0],
      address: expandId(decompressStreet(t[1])),
      type: convertIntToSaleType(parseInt(t[2])),
      price: parseFloat(t[3]),
      unitPrice: parseFloat(t[4]),
      area: parseFloat(t[5]),
      areaType: t[6],
      purchaserAddrIndicator: t[7],
      postal: t[8],
      propertyType: convertIntToPropertyType(parseInt(t[9])),
      profitPerc: parseFloat(t[10]),
      profit: parseFloat(t[11]),
      prevTxYears: t[12]
    })) : [],
    rentals: l[3] ? l[3].split('|').map(t => t.split(',')).map(t => ({
      leaseDate: t[0],
      address: expandId(decompressStreet(t[1])),
      propertyType: convertIntToPropertyType(parseInt(t[2])),
      bedrooms: t[3] ? parseInt(t[3]) : null,
      monthlyRent: parseFloat(t[4]),
      area: t[5],
      minArea: parseFloat(t[6]),
      maxArea: parseFloat(t[7]),
      estPsf: parseFloat(t[8])
    })) : [],
    comps: l[4] ? l[4].split('|').map(t => t.split(',')).map(t => ({
      marker: expandId(t[0]),
      project: expandId(t[1]),
      projectId: expandId(t[2]),
      distance: parseFloat(t[3]),
      profitable: t[4] ? parseFloat(t[4]) : null,
      last3mAvgPsf: t[5] ? parseFloat(t[5]) : null,
      types: convertIntToPropertyType(parseInt(t[6])),
      hdbBuyers: t[7] ? parseFloat(t[7]) : null,
      completion: t[8],
      tenures: convertIntToTenureType(t[9]),
      totalUnits: t[10] ? parseInt(t[10]) : null,
      soldAtLaunch: t[11] ? parseFloat(t[11]) : null,
    })) : [],
    blocks: l[5] ? l[5].split('|').map(t => t.split(',')).map(t => ({
      address: expandId(decompressStreet(t[0])),
      block: t[1],
      postal: t[2],
      lat: parseFloat(t[3]),
      lng: parseFloat(t[4]),
      schools: t[5] ? t.slice(5).map(s => capitalizeAll(expandId(s))) : [],
      type: LOCATION_BLOCK
    })) : [],
    schools: l[6] ? l[6].split('|').map(t => t.split(',')).map(t => ({
      name: capitalizeAll(expandId(t[0])),
      lat: parseFloat(t[1]),
      lng: parseFloat(t[2]),
      type: LOCATION_SCHOOL
    })) : [],
  };
};

export const decompressAmenityData = (txt) => txt.split('|')
  .map(line => {
    const l = line.split(',');
    return {
      marker: expandId(l[0]),
      project: expandId(l[1]),
      projectId: l[2],
      postal: l[3],
      street: expandId(l[4]),
      lat: parseFloat(l[5]),
      lng: parseFloat(l[6])
    }
  });

export const decompressGroupCompTxData = (txt) => txt.split('|')
  .map(line => {
    const l = line.split(',');
    return {
      saleDate: l[0],
      saleType: l[1],
      unitPrice: l[2] ? parseFloat(l[2]) : null
    };
  });

export const decompressGroupCompProfitData = (txt) => txt.split('|')
  .map(line => {
    const l = line.split(',');
    return {
      saleDate: l[0],
      saleType: l[1],
      profitPerc: l[2] ? parseFloat(l[2]) : null
    };
  });

// export const getGridFromLatLng = (lat, lng) => {
//   const latIdx = Math.floor((lat - 1.16) / 0.01);
//   const lngIdx = Math.floor((lng - 103.6) / 0.01);
//   return [latIdx, lngIdx];
// };

// export const convertLatLngGrids = (props) => {
//   const grids = Array.from({ length: 33 }, () => Array(43).fill([]));
//   props.forEach(p => {
//     const idx = getGridFromLatLng(p.lat, p.lng);
//     grids[idx[0]][idx[1]].push(p);
//   });
//   return grids;
// };

// export const getPropsInMapGrids = (grids, bounds) => {
//   const c1 = getGridFromLatLng(bounds[0], bounds[1]);
//   const c2 = getGridFromLatLng(bounds[4], bounds[5]);
//   const props = [];
//   for (let i = c2[0]; i <= c1[0]; i++) {
//     for (let j = c2[1]; j <= c1[1]; j++) {
//       grids[i][j].forEach(p => props.push({
//         name: p.name,
//         coords: [p.lat, p.lng]
//       }));
//     }
//   }
//   return props;
// };
