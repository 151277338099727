import { Layer, Source } from "react-map-gl/maplibre";
import * as turf from '@turf/turf';
import { radiusLabelStyle, radiusMaskStyle } from "@/utils/map";

const RadiusOverlay = ({ target, radiusKm }) => {
  return (
    <>
      <Source id={`mask-data-${radiusKm}`} type="geojson" data={turf.featureCollection([
        turf.polygon([[
          [-180, -90],
          [-180, 90],
          [180, 90],
          [180, -90],
          [-180, -90]
        ], turf.circle(
          [ target.lng, target.lat ], radiusKm,
          { units: 'kilometers' }
        ).geometry.coordinates[0]])
      ])}>
        <Layer {...radiusMaskStyle(radiusKm)} />
      </Source>

      <Source id={`mask-label-${radiusKm}`} type="geojson" data={{
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: turf.destination(
                [ target.lng, target.lat ], radiusKm, 0,
                { units: 'kilometers' }
              ).geometry.coordinates
            },
            properties: {
              label: `approx ${radiusKm}km radius`
            }
          }
        ]
      }}>
        <Layer {...radiusLabelStyle(radiusKm)} />
      </Source>
    </>
  );
};

export default RadiusOverlay;
