import { useState } from "react";
import FairValue from "./Nearby/FairValue";
import { noSelectClass } from "@/utils/user";
import SimilarPropsTable from "./Table/SimilarPropsTable";
import NearbySchools from "./Nearby/NearbySchools";
import NearbyStations from "./Nearby/NearbyStations";

const NEARBY_OPTIONS = [
  { id: 'schools', label: 'Nearby Schools '},
  // { id: 'stations', label: 'Nearby MRT/LRT' },
  { id: 'properties', label: 'Nearby Similar Properties' },
];

const NearbyTab = ({
  user,
  data,
  mapData,
  basicData,
  goToProperty,
  isMaximized,
  focus,
  setFocus
}) => {
  const [display, setDisplay] = useState(NEARBY_OPTIONS[0].id);
  const property = {
    ...mapData,
    ...basicData,
    fairPsf: data.fairPsf
  };

  return (
    <>
      <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector px-0 noselect">
        <div
          className="dropdown__button dropdown__button_sm d-flex items-center bg-white border-light rounded-100 px-15 py-5 text-12 lh-12"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          data-bs-offset="0,10"
        >
          <span className="js-dropdown-nearby-opt">{NEARBY_OPTIONS.find(o => o.id === display).label}</span>
          <i className="icon icon-chevron-sm-down text-7 ml-10" />
        </div>
        <div className="toggle-element -dropdown dropdown-menu dropdown-top">
          <div className="text-12 y-gap-20 js-dropdown-list dropdown-top">
            {NEARBY_OPTIONS.map((option, index) => (
              <div key={index}>
                <button
                  className={`d-block js-dropdown-link ${
                    display === option.id ? "text-blue-1 " : ""
                  }`}
                  onClick={() => {
                    setDisplay(option.id);
                    document.querySelector(".js-dropdown-nearby-opt").textContent = option.label;
                  }}
                >
                  {option.label}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {display === 'schools'
        && <section className="px-5">
          <div className={`${noSelectClass(user)}`}>
            <NearbySchools
              blocks={data.blocks}
              schools={data.schools}
              isMaximized={isMaximized}
              focus={focus}
              setFocus={setFocus}
            />
          </div>
        </section>
      }

      {display === 'stations'
        && <section className="px-5">
          <div className={`${noSelectClass(user)}`}>
            <NearbyStations
              data={basicData}
              blocks={data.blocks}
              isMaximized={isMaximized}
              focus={focus}
              setFocus={setFocus}
            />
          </div>
        </section>
      }

      {display === 'properties'
        && <section className={`mb-20 pt-10 modal-table${isMaximized ? '-full' : ''}`}>
          <FairValue data={property} />

          <div className={`data-table mt-20 pt-10 border-top-light ${noSelectClass(user)}`}>
            <div className="d-flex">
              <div className="p-2">
                <h3 className="text-15 fw-500">Compare Similar Properties</h3>
              </div>
            </div>

            <SimilarPropsTable
              property={property}
              data={data.comps}
              goToProperty={goToProperty}
            />
          </div>
        </section>
      }
    </>
  );
};

export default NearbyTab;
