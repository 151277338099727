import { checkMediaQuery } from "@/utils/user";
import { useState } from "react";
import ScrollToTop from "@/components/project/ScrollToTop";
import CompareView from "./property/CompareView";
import Loader from "../common/Loader";

const CompareProView = ({
  user,
  screenDim,
  isMaximized,
  isMinimized,
  goToProperty,
  setFocusOn,
  compareList,
  setCompareList,
  closeDetails
}) => {
  const [loading, setLoading] = useState(false);
  
  const compData = { t: {}, p: {} };
  Object.keys(compareList).forEach(p => {
    compData.t[p] = compareList[p].t;
    compData.p[p] = compareList[p].p;
  });

  const setCompData = (data) => {
    const newCompareList = {};
    Object.keys(data.t).forEach(p => {
      newCompareList[p] = {
        t: data.t[p],
        p: data.p[p] ?? []
      };
    });
    setCompareList(newCompareList);
  };

  const mediaMatches = checkMediaQuery();

  return (
    <>
      {loading
        && <div className="loader-container">
          <Loader />
        </div>
      }

      {!isMinimized && !loading && Object.keys(compareList).length === 0
        && <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-between text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
              <div
                className="p-2 pt-5 pb-0 cursor-pointer"
                onClick={() => closeDetails()}
              >
                <i className="icon-close py-5" />
              </div>
            </div>
          }

          {!mediaMatches
            && <div className="d-flex justify-content-end text-10 text-light-1">
              <div
                className="p-2 px-10 pt-5 pb-0 cursor-pointer"
                onClick={() => closeDetails()}
              >
                <i className="icon-close" />
              </div>
            </div>
          }

          <div className="modal-body watermark d-flex">
            {!mediaMatches
              && <div
                className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                  <i className="icon-chevron-sm-down px-5" />
                </div>
              </div>
            }

            <div
              id="project-details-modal"
              className={`p-2 modal-scoll-content text-center fw-500 py-40 ${
                isMaximized ? 'modal-scroll-media-full' : (mediaMatches ? 'modal-scroll-media' : '')
              }`}
            >
              No properties in compare list yet
            </div>
          </div>
        </div>
      }

      {!isMinimized && !loading && Object.keys(compareList).length > 0 && (
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-between text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
              <div
                className="p-2 pt-5 pb-0 cursor-pointer"
                onClick={() => closeDetails()}
              >
                <i className="icon-close py-5" />
              </div>
            </div>
          }

          {!mediaMatches
            && <div className="d-flex justify-content-end text-10 text-light-1">
              <div
                className="p-2 px-10 pt-5 pb-0 cursor-pointer"
                onClick={() => closeDetails()}
              >
                <i className="icon-close" />
              </div>
            </div>
          }

          <div className="modal-body watermark d-flex">
            {!mediaMatches
              && <div
                className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                  <i className="icon-chevron-sm-down px-5" />
                </div>
              </div>
            }

            <div
              id="project-details-modal"
              className={`p-2 modal-scoll-content ${
                isMaximized ? 'modal-scroll-media-full' : (mediaMatches ? 'modal-scroll-media' : '')
              }`}
            >

              <ScrollToTop />

              <h1 className="text-18 sm:text-20 fw-600 mt-10 mb-20">
                Compare PRO
              </h1>
            
              <CompareView
                user={user}
                loading={loading}
                setLoading={setLoading}
                compData={compData}
                setCompData={setCompData}
                screenDim={screenDim}
                isMaximized={isMaximized}
                goToProperty={goToProperty}
                setFocusOn={setFocusOn}
                compareList={compareList}
                setCompareList={setCompareList}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CompareProView;
