import { checkMediaQuery, noSelectClass } from "@/utils/user";
import ScrollToTop from "../project/ScrollToTop";
import UpcomingCompTable from "./property/Table/UpcomingCompTable";
import { NA } from "@/utils/convert";

const UpcomingView = ({
  user,
  err,
  target,
  isMinimized,
  isMaximized,
  handleMaximize,
  handleExpand,
  closePropertyDetails,
  goToProperty
}) => {
  const mediaMatches = checkMediaQuery();

  return (
    <>
      {!isMinimized && err &&
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-between text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
              <div
                className="p-2 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close py-5" />
              </div>
            </div>
          }

          {!mediaMatches
            && <div className="d-flex justify-content-end text-10 text-light-1">
              <div
                className="p-2 px-10 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close" />
              </div>
            </div>
          }

          <div className="modal-body watermark d-flex">
            {!mediaMatches
              && <div
                className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                  <i className="icon-chevron-sm-down px-5" />
                </div>
              </div>
            }

            <div
              id="project-details-modal"
              className={`p-2 modal-scoll-content text-center fw-500 py-40 ${
                isMaximized ? 'modal-scroll-media-full' : (mediaMatches ? 'modal-scroll-media' : '')
              }`}
            >
              Property not found
            </div>
          </div>
        </div>
      }

      {!isMinimized && !err && (
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-between text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
              <div
                className="p-2 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close py-5" />
              </div>
            </div>
          }

          {!mediaMatches
            && <div className="d-flex justify-content-end text-10 text-light-1">
              <div
                className="p-2 px-10 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close" />
              </div>
            </div>
          }

          <div className="modal-body watermark d-flex">
            {!mediaMatches
              && <div
                className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                  <i className="icon-chevron-sm-down px-5" />
                </div>
              </div>
            }

            <div
              id="project-details-modal"
              className={`p-2 modal-upcoming ${
                isMaximized ? 'modal-upcoming-media-full' : (mediaMatches ? 'modal-upcoming-media' : '')
              }`}
            >

              <ScrollToTop />
            
              <section className={`modal-table${isMaximized ? '-full' : ''}`}>
                <div className={`data-table ${noSelectClass(user)}`}>
                  <div className="d-flex noselect">
                    <i className="p-2 icon-home text-18" />
                    <h3 className="p-2 text-15 fw-500">{target.id}</h3>
                  </div>

                  <div className="mt-10 px-10 py-10 border-top-light">
                    <div className="row x-gap-50 y-gap-0 text-12 pt-5">
                      <div className="col-12">
                        <div className="">
                          <span className="proj-lw">Street:</span>
                          {target.data.street}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="">
                          <span className="proj-lw">Type:</span>
                          {target.data.types.join(', ')}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="">
                          <span className="proj-lw">Tenure:</span>
                          {target.data.tenures.join(', ')}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="">
                          <span className="proj-lw">Completion:</span>
                          {target.data.completion ? target.data.completion : NA}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="">
                          <span className="proj-lw">Profitable %:</span>
                          {target.data.profitable ?? NA}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-top-light mt-10 py-10"></div>

                  <UpcomingCompTable
                    property={target}
                    data={target.data.comps}
                    goToProperty={goToProperty}
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpcomingView;
