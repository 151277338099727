import { useCallback, useState } from "react";
import { debounce } from 'lodash';

import { DEBOUNCE_TIMING, searchQuery } from "@/utils/api";
import LocationSearch from "@/components/mapv2/search/LocationSearch";

const FilterBox = ({ onSearch }) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const onInputSearch = (value) => {
    setLoading(true);
    searchQuery(value.trim(), 20, (data) => {
      const newData = data.filter(d => d.names.length === 1);
      data.filter(d => d.names.length > 1).forEach(p => {
        p.names.forEach(n => {
          newData.push({
            ...p,
            names: [n]
          });
        });
      });
      setResults(newData.sort((a, b) => a.type.localeCompare(b.type)));
      setLoading(false);
    }, () => {
      setLoading(false);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedSearch = useCallback(debounce(onInputSearch, DEBOUNCE_TIMING), []);

  const onInput = (item) => {
    setSearchValue(item);
    if (item?.length >= 3) onDebouncedSearch(item);
  };

  const onSelect = (item) => {
    onSearch(item);
    setSearchValue('');
  };

  const onKeyDown = () => {
    if (results?.length > 0) {
      onSearch(results[0]);
      setSearchValue('');
    }
  };

  return (
    <>
      <div className="mainSearch bg-white rounded-4 proj-search noselect">
        <div className="button-grid items-center d-flex justify-content-between">
          <div className="p-2 flex-fill">
            <LocationSearch
              searchValue={searchValue}
              results={results}
              loading={loading}
              onInput={onInput}
              onSelect={onSelect}
              onKeyDown={onKeyDown}
            />
          </div>

          <div className="button-item h-full">
            <button
              className="button -dark-1 py-15 px-10 h-full col-12 rounded-0 bg-blue-1 text-white text-12"
              onClick={onKeyDown}
            >
              <i className="icon-search mr-5" />
              Search
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterBox;
