import { useState } from 'react';

import CustomTable from '@/components/transactions/CustomTable';
import { convertDisplayDate, dateStrToMsec } from '@/utils/time';
import { STANDARD_PAGE_CONFIG } from '@/utils/table';
import { Link } from 'react-router-dom';
import { calculateAnnualized, getBlockUnitFromAddress } from '@/utils/areas';

const TransactionTable = ({
  raw,
  data,
  onFilterButtonClick,
  goToUnit
}) => {
  const DEFAULT_COLUMNS = [
    {
      Header: 'Sale Date',
      accessor: 'sale_date',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div>{convertDisplayDate(new Date(row.cell.value))}</div>
    },
    {
      Header: 'Address',
      accessor: 'address',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: (row) => <Link
        className="text-blue-1"
        data-bs-toggle="offcanvas"
        data-bs-target="#listingDetails"
        onClick={() => {
          const txs = raw.filter(t => t.address === row.cell.value).map(t => ({ ...t, ts: dateStrToMsec(t.saleDate) }));
          const unit = {
            displayBlock: getBlockUnitFromAddress(row.cell.value).block,
            tx: txs,
            gains: txs.map((tx, i) => {
              const gain = calculateAnnualized(i, txs);
              return gain !== null ? {
                ts: dateStrToMsec(tx.saleDate),
                gain,  
              } : null
            }),
          };
          goToUnit(unit);
        }}
      >
        {row.cell.value}
      </Link>
    },
    {
      Header: 'Type of Sale',
      accessor: 'type',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'Transacted Price ($)',
      accessor: 'transacted_price',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div>${row.cell.value.toLocaleString()}</div>
    },
    {
      Header: 'Unit Price ($psf)',
      accessor: 'unitPrice',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div>${row.cell.value.toLocaleString()}</div>
    },
    {
      Header: 'Property Type',
      accessor: 'propertyType',
      disableGlobalFilter: true,
    },
    {
      Header: 'Area (sft)',
      accessor: 'area',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div>{row.cell.value.toLocaleString()}</div>
    },
    {
      Header: 'Type of Area',
      accessor: 'areaType',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'Purchaser Address Indicator',
      accessor: 'purchaserAddrIndicator',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'Postal Code',
      accessor: 'postal',
      disableGlobalFilter: true,
      width: 80,
    },
  ];

  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  const dateStrConvert = (dateStr) => {
    const chunks = dateStr.split('-');
    const day = chunks[0];
    const month = chunks[1];
    const year = chunks[2];
    return new Date(`${year}-${month}-${day}`).getTime();
  };

  return (
    <CustomTable
      name="tx-tb"
      data={
        data.map(row => ({
          ...row,
          'sale_date': dateStrConvert(row.saleDate),
          'transacted_price': parseInt(row.price),
        })).sort((a, b) => b.sale_date - a.sale_date)
      }
      tableConfig={STANDARD_PAGE_CONFIG}
      defaultColumns={DEFAULT_COLUMNS}
      fullColumns={fullColumns}
      setFullColumns={setFullColumns}
      columns={columns}
      setColumns={setColumns}
      hideSearchInput
      hidePageButton
      showFilterButton
      onFilterButtonClick={onFilterButtonClick}
    />
  );
};

export default TransactionTable;
