import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { debounce } from 'lodash';

import LocationSearch from "@/components/mapv2/search/LocationSearch";
import { DEBOUNCE_TIMING, searchQuery } from "@/utils/api";

const SearchCompareProp = ({ onSearch, filterType }) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const onInputSearch = (value) => {
    setLoading(true);
    searchQuery(value.trim(), 20, (data) => {
      setResults(data.sort((a, b) => a.type.localeCompare(b.type)));
      setLoading(false);
    }, () => {
      setLoading(false);
    }, filterType);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedSearch = useCallback(debounce(onInputSearch, DEBOUNCE_TIMING), []);

  const onInput = (item) => {
    setSearchValue(item);
    if (item?.length >= 3) onDebouncedSearch(item);
  };

  const onSelect = (item) => {
    onSearch(item);
    setSearchValue('');
  };

  const onKeyDown = () => {
    if (results?.length > 0) {
      onSearch(results[0]);
      setSearchValue('');
    }
  };

  return (
    <>
      <div className="mainSearch bg-white rounded-4 proj-comp-search">
        <div className="button-grid items-center d-flex justify-content-between">
          <div className="p-2 flex-fill">
            <LocationSearch
              searchValue={searchValue}
              results={results}
              loading={loading}
              onInput={onInput}
              onSelect={onSelect}
              onKeyDown={onKeyDown}
              hint="Enter project, street, postal code"
            />
          </div>

          <div className="button-item h-full">
            <button
              className="button -dark-1 py-15 px-20 h-full col-12 rounded-0 bg-blue-1 text-white"
              onClick={onKeyDown}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

SearchCompareProp.propTypes = {
  onSearchLocation: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  hideClickHint: PropTypes.bool,
  buttonIcon: PropTypes.string,
  buttonLabel: PropTypes.string,
};

SearchCompareProp.defaultProps = {
  buttonIcon: null,
  buttonLabel: null,
};

export default SearchCompareProp;
