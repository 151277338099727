import { Marker } from "react-map-gl/maplibre";

const SchoolMarker = ({ idx, school, onClick }) => {
  const getSchoolLabel = (subtype) => {
    if (subtype === 'Preschool') return 'Preschool';
    if (subtype === 'Primary') return 'Primary School';
    if (subtype === 'Secondary') return 'Secondary School';
    if (subtype === 'Post Secondary') return 'Post-Secondary Education';
    return subtype;
  };
  
  return (
    <Marker
      key={`scm-${idx}`}
      latitude={school.coords[0]}
      longitude={school.coords[1]}
      anchor="bottom"
      onClick={onClick}
    >
      <div
        className="cursor-pointer"
        data-tooltip-id="amenity-tooltip"
        data-tooltip-html={`
          <div class="map-tooltip-content">
          <span class="fw-500">${school.names.join('<br />')}</span>
          <br />
          <span class="text-light-2">${school.subtype.map(t => getSchoolLabel(t)).join(', ')}</span>
          </div>
        `}
        data-tooltip-variant="dark"
        data-tooltip-place="bottom"
      >
        <div>
          <img src="/img/general/school.png" width={36} />
        </div>
      </div>
    </Marker>
  );
};

export default SchoolMarker;
