import { checkMediaQuery, noSelectClass } from "@/utils/user";
import ScrollToTop from "../project/ScrollToTop";
import AmenityTable from "./property/Table/AmenityTable";
import { useEffect, useState } from "react";
import { calculateDist } from "@/utils/convert";
import { decompressAmenityData } from "@/utils/map";
import Loader from "../common/Loader";
import { capitalize } from "@/utils/text";
import { searchSearchSpecificAmenity } from "@/utils/api";

const AmenityView = ({
  user,
  target,
  isMinimized,
  isMaximized,
  handleMaximize,
  handleExpand,
  closePropertyDetails,
  goToProperty,
  goToBlock
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getTypeFolder = () => {
    if (target.type === 'school') return 's';
    if (target.type === 'station') return 't';
    return null;
  };

  const loadData = (store) => {
    fetch(`https://realsmart.global.ssl.fastly.net/d/${getTypeFolder()}/${store}`)
    .then(resp => {
      return resp.text();
    }).then(txt => {
      const data = decompressAmenityData(txt);
      setData(data);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    if (!target.store) {
      searchSearchSpecificAmenity(target.id, data => {
        loadData(data.store);
      }, err => {
        setLoading(false);
      })
    } else {
      loadData(target.store);
    }
  }, []);

  const mediaMatches = checkMediaQuery();

  return (
    <>
      {loading
        && <div className="loader-container">
          <Loader />
        </div>
      }

      {!isMinimized && !loading && (!data || data.length === 0) &&
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-between text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
              <div
                className="p-2 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close py-5" />
              </div>
            </div>
          }

          {!mediaMatches
            && <div className="d-flex justify-content-end text-10 text-light-1">
              <div
                className="p-2 px-10 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close" />
              </div>
            </div>
          }

          <div className="modal-body watermark d-flex">
            {!mediaMatches
              && <div
                className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                  <i className="icon-chevron-sm-down px-5" />
                </div>
              </div>
            }

            <div
              id="project-details-modal"
              className={`p-2 modal-scoll-content text-center fw-500 py-40 ${
                isMaximized ? 'modal-scroll-media-full' : (mediaMatches ? 'modal-scroll-media' : '')
              }`}
            >
              {capitalize(target.type)} not found
            </div>
          </div>
        </div>
      }

      {!isMinimized && !loading && (
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-between text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
              <div
                className="p-2 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close py-5" />
              </div>
            </div>
          }

          {!mediaMatches
            && <div className="d-flex justify-content-end text-10 text-light-1">
              <div
                className="p-2 px-10 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close" />
              </div>
            </div>
          }

          <div className="modal-body watermark d-flex">
            {!mediaMatches
              && <div
                className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                  <i className="icon-chevron-sm-down px-5" />
                </div>
              </div>
            }

            <div
              id="project-details-modal"
              className={`p-2 modal-scoll-content ${
                isMaximized ? 'modal-scroll-media-full' : (mediaMatches ? 'modal-scroll-media' : '')
              }`}
            >

              <ScrollToTop />
            
              <section className={`modal-table${isMaximized ? '-full' : ''} mb-50`}>
                <div className={`data-table ${noSelectClass(user)}`}>
                  <div className="d-flex noselect">
                    <i className="p-2 icon-location-2 text-18" />
                    <h3 className="p-2 text-15 fw-500">{target.name ?? target.names?.join(', ')}</h3>
                  </div>
                  <AmenityTable
                    data={data?.map(p => ({
                      ...p,
                      distance: calculateDist(target.lat, target.lng, p.lat, p.lng)
                    })).map(p => ({
                      ...p,
                      distance: p.distance <= 1000 ? p.distance : 1000
                    })).sort((a, b) => a.distance - b.distance) ?? []}
                    goToProperty={(marker, project) => {
                      goToProperty(marker, project)
                    }}
                    goToBlock={goToBlock}
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AmenityView;
