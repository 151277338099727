import { NA } from "@/utils/convert";
import { getPropertyMarkerColor, getPropertyTextColor } from "@/utils/map";
import { Marker } from "react-map-gl/maplibre";

const AreaMarker = ({
  idx,
  area,
  onClick,
  markerScheme
}) => {
  const markerScale = Math.min(1, 0.5 + ((area.totalTx ?? 0) / 20000));
  const markerColor = getPropertyMarkerColor(area, markerScheme);

  return (
    <Marker
      key={`arm-${idx}`}
      latitude={area.lat}
      longitude={area.lng}
      anchor="bottom"
      onClick={onClick}
    >
      <div
        style={{
          opacity: 1,
          transform: `scale(${markerScale})`
        }}
        data-tooltip-id="map-tooltip"
        data-tooltip-html={`
          <div class="map-tooltip-content">
          <span class="fw-500">${area.id}</span>
          <div class="map-tooltip-l-content">
          Properties: ${area.properties.length}
          <br />
          Avg profitable %: <span class="${getPropertyTextColor(area, 'profitable')}">${area.profitable !== null ? area.profitable.toFixed(0) + '%' : NA}</span>
          <br />
          Avg PSF (Last 3 months): <span class="${getPropertyTextColor(area, 'fairPsf')}">${area.avgPsf3m ? '$' + area.avgPsf3m.toFixed(2) : NA}</span> ${area.fairPsf && area.fairPsf > 0 ? '(avg fair PSF: $' + area.fairPsf.toFixed(2) + ')' : ''}
          </div>
          <span class="map-tooltip-hint">Click to view details</span>
          </div>
        `}
        data-tooltip-variant="dark"
        data-tooltip-place="bottom"
      >
        <svg
          height={60}
          viewBox="0 0 24 24"
          style={{
            cursor: 'pointer',
            fill: markerColor,
            stroke: 'none',
          }}
        >
          <circle cx="12" cy="10" r="8" fill={markerColor} />
          <text x='12' y='12.5' textAnchor="middle" fill="#fff" style={{ fontSize: '7px', fontWeight: '600' }} >
            {area.properties.length}
          </text>
        </svg>
      </div>
    </Marker>
  );
};

export default AreaMarker;
