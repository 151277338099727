import { NA } from "@/utils/convert";
import {
  ICON,
  getPropertyBgColor,
  getPropertyMarkerColor,
  getPropertyTextColor
} from "@/utils/map";
import { checkMediaQuery } from "@/utils/user";
import { Marker } from "react-map-gl/maplibre";

const PropertyMarker = ({
  idx,
  target,
  property,
  goToProperty,
  data,
  markerScheme
}) => {
  const markerScale = Math.min(1, 0.7 + ((data.totalTx ?? 0) / 300));
  const markerColor = getPropertyMarkerColor(data, markerScheme);

  const getMarkerText = () => {
    if (markerScheme === 'profitable') return data.profitable !== null && data.profitable >= 0 ? `${data.profitable.toFixed(0)}` : NA;
    if (markerScheme === 'avgPsf3m') return data.avgPsf3m !== null && data.avgPsf3m >= 0 ? `${(data.avgPsf3m/1000).toFixed(1)}K` : NA;
    return '';
  };

  const names = [...new Set([ property.marker, ...property.names ])];

  return (
    <Marker
      key={`propm-${idx}`}
      latitude={property.coords[0]}
      longitude={property.coords[1]}
      anchor="bottom"
      onClick={e => {
        e.originalEvent.stopPropagation();
        goToProperty(property.marker);
      }}
    >
      <div
        style={{
          opacity: 1,
          transform: `scale(${markerScale})`
        }}
        data-bs-toggle="offcanvas"
        data-bs-target="#propSidebar"
        data-tooltip-id="prop-tooltip"
        data-tooltip-html={checkMediaQuery() ? `<div class="map-tooltip-content"><span class="fw-500">${names.join('<br/>')}</span></div>` : `
          <div class="map-tooltip-content">
          <span class="fw-500">${names.join('<br/>')}</span>
          <div class="map-tooltip-l-content">
          Type: ${data.types.join(', ')}
          <br />
          Profitable %: <span class="${getPropertyTextColor(data, 'profitable')}">${data.profitable === null || data.profitable < 0 ? NA : `${data.profitable.toFixed(0)}%`}</span>
          <br />
          Total Transactions: ${data.totalTx}
          </div>
          <span class="map-tooltip-hint">${target?.id === property.marker ? 'Current property' : 'Click to view details'}</span>
          </div>
        `}
        data-tooltip-variant="dark"
        data-tooltip-place="bottom"
      >
        {target?.id === property.marker && <div className={`prop-marker-roof ${getPropertyBgColor(data, 'profitable')}`}></div>}
        <svg
          height={60}
          viewBox="0 0 24 24"
          style={{
            cursor: 'pointer',
            fill: markerColor,
            stroke: 'none',
          }}
        >
          <path d={ICON} />
          <circle cx="12" cy="10" r="8" fill="#fff" />
          <text x='12' y='12.5' textAnchor="middle" fill={markerColor} style={{ fontSize: '7px', fontWeight: '600' }} >
            {getMarkerText()}
          </text>
        </svg>
      </div>
    </Marker>
  );
};

export default PropertyMarker;
