import Aos from "aos";
import { useEffect, useState } from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import * as Sentry from "@sentry/react";

import SrollTop from "./components/common/ScrollTop";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-cards";
import "aos/dist/aos.css";
import "./styles/index.scss";
import { Provider } from "react-redux";
import { store } from "./store/store";

import "maplibre-gl/dist/maplibre-gl.css";

if (typeof window !== "undefined") {
  import("bootstrap");
}
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollTopBehaviour from "./components/common/ScrollTopBehaviour";

import NotFoundPage from "./pages/not-found";
import LogIn from "./pages/others/login";
import Terms from "./pages/others/terms";
import About from "./pages/others/about";
// import HelpCenter from "./pages/others/help-center";
// import SignUp from "./pages/others/signup";
// import Invoice from "./pages/others/invoice";
// import Contact from "./pages/others/contact";

// import SearchPage from "./pages/search";
// import ProjectPage from "./pages/project";
// import ComparePage from "./pages/compare";
// import UpcomingPropsPage from "./pages/upcoming";
// import UnitsViewPage from "./pages/blocks";
import TransactionSearchPage from "./pages/transactions";
import HeatmapPage from "./pages/heatmap";
import ProfitSearchPage from "./pages/profit";
import RentalSearchPage from "./pages/rental";
import AdminPage from "./pages/admin";
// import PendingPage from "./pages/pending";
// import PricingPage from "./pages/pricing";
// import ConfirmationPage from "./pages/confirmation";
// import BillingPage from "./pages/billing";
// import RefreshPage from "./pages/refresh";
// import ContactPage from "./pages/contact";
import LandingPage from "./pages/landing";
import DetailsMapPage from "./pages/details";
import HomeMap from "./pages/mapv2";

Sentry.init({
  dsn: "https://2f9b6b613efe149b025cfb3fe9350773@o4507704266326016.ingest.us.sentry.io/4507704270323712",
  integrations: [],
});

function App() {
  const [user, setUser] = useState(null);
  const session = Math.random().toString(36).slice(2);

  useEffect(() => {
    Aos.init({
      duration: 1200,
      once: true,
    });
  }, []);

  const refreshUser = (callback) => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            user.getIdTokenResult().then((result) => {
              setUser({
                ...user,
                result,
                claims: result.claims,
              });
              callback?.(result.claims);
            });
          })
          .catch(() => {
            setUser({});
          });
      } else {
        setUser({});
      }
    });
    return () => unregisterAuthObserver();
  };

  const getUser = () => firebase.auth().currentUser;

  const forceRefreshUser = (callback) => {
    const user = getUser();
    if (user) {
      user.getIdToken(true).then(() => {
        user.getIdTokenResult().then((result) => {
          callback?.(result.claims);
        });
      });
    }
  };

  const updateTokenResult = (results) => {
    const name = results.claims.name;
    const picture = results.claims.picture;
    const email = results.claims.email;
    setUser({
      multiFactor: {
        ...user?.multiFactor,
        user: {
          ...user?.multiFactor?.user,
          displayName: name,
          email: email,
          photoURL: picture,
        },
      },
      results,
      claims: results.claims,
    })
  };

  // handle user auth state
  useEffect(() => {
    refreshUser();
  }, []);

  return (
    <main>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              {/* <Route index element={<SearchPage user={user} session={session} />} /> */}
              <Route index element={<LandingPage user={user} session={session} />} />
              {/* <Route path="search" element={<SearchPage user={user} session={session} />} /> */}
              {/* <Route
                path="project/:id"
                element={<ProjectPage user={user} session={session} />}
              /> */}
              <Route path="project/:id" element={<DetailsMapPage user={user} />} />
              <Route path="app" element={<HomeMap user={user} />} />
              <Route path="transactions" element={<TransactionSearchPage user={user} session={session} />} />
              <Route path="profits" element={<ProfitSearchPage user={user} session={session} />} />
              <Route path="rentals" element={<RentalSearchPage user={user} session={session} />} />
              <Route path="map" element={<HeatmapPage user={user} session={session} />} />
              {/* <Route path="compare" element={<ComparePage user={user} session={session} />} /> */}
              {/* <Route path="units" element={<UnitsViewPage user={user} session={session} />} /> */}
              {/* <Route path="upcoming" element={<UpcomingPropsPage user={user} session={session} />} /> */}

              <Route path="login" element={<LogIn session={session} updateTokenResult={updateTokenResult} />} />
              {/* <Route path="pricing" element={<PricingPage user={user} session={session} />} />
              <Route path="billing" element={<BillingPage user={user} session={session} />} />
              <Route path="superpower" element={<ConfirmationPage user={user} session={session} refreshUser={forceRefreshUser} />} /> */}
              {/* <Route path="pending" element={<PendingPage user={user} refreshUser={forceRefreshUser} getUser={getUser} />} />
              <Route path="refresh" element={<RefreshPage user={user} refreshUser={forceRefreshUser} getUser={getUser} updateTokenResult={updateTokenResult} />} /> */}

              <Route path="404" element={<NotFoundPage user={user} session={session} />} />
              <Route path="*" element={<NotFoundPage user={user} session={session} />} />

              <Route path="terms" element={<Terms user={user} session={session} />} />
              <Route path="about" element={<About user={user} session={session} />} />
              <Route path="contact" element={<About user={user} session={session} />} />
              {/* <Route path="contact" element={<ContactPage user={user} />} /> */}

              <Route path="start" element={<LandingPage user={user} session={session} />} />
              <Route path="admin" element={<AdminPage user={user} />} />

              {/* <Route path="help-center" element={<HelpCenter />} /> */}
              {/* <Route path="signup" element={<SignUp />} /> */}
              {/* <Route path="invoice" element={<Invoice />} /> */}
              {/* <Route path="contact" element={<Contact />} /> */}
              
            </Route>
          </Routes>
          <ScrollTopBehaviour />
        </BrowserRouter>

        <SrollTop />
      </Provider>
    </main>
  );
}

export default App;
