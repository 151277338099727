import { useEffect, useState } from "react";
import DiscussionTab from "@/components/project/DiscussionTab";
import ScrollToTop from "@/components/project/ScrollToTop";
import { checkMediaQuery, continueIfAllowedUser } from "@/utils/user";
import { useLocation, useNavigate } from "react-router-dom";
import TabButtons from "../project/TabButtons";
import { downloadFileUrl } from "@/utils/api";
import {
  decompressAdvPropertyData,
  decompressBasicPropertyData,
  getPropertyFileName
} from "@/utils/map";
import Loader from "@/components/common/Loader";
import OverviewTab from "./property/OverviewTab";
import TransactionsTab from "./property/TransactionTab";
import RentalTab from "./property/RentalTab";
import UnitsTab from "./property/UnitsTab";
import { NA } from "@/utils/convert";
import { convertDisplayDateMsec, formatShortDate } from "@/utils/time";
import AnnotatedChart from "./chart/AnnotatedChart";
import NearbyTab from "./property/NearbyTab";
import CompareTab from "./property/CompareTab";

const TABS = [
  { id: 'overview', label: 'Overview' },
  { id: 'transactions', label: 'Transactions' },
  { id: 'rental', label: 'Rental' },
  // { id: 'compare', label: 'Compare' },
  { id: 'nearby', label: 'Nearby' },
  { id: 'units', label: 'Stack View' },
  // { id: 'discuss', label: 'Discussion' },
];

const TABS_REQUIRE_ADV_DATA = new Set([
  'transactions',
  'rental',
  'units',
  'nearby'
]);

const PropertyView = ({
  user,
  session,
  target,
  marker,
  setMarkerData,
  isMinimized,
  isMaximized,
  handleExpand,
  handleMaximize,
  closePropertyDetails,
  goToProperty,
  screenDim,
  focus,
  setFocus,
  setFocusOn,
  compareList,
  setCompareList,
  addToCompareList,
  mapData,
  viewComparePro
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedTab, setSelectedTab] = useState(TABS[0].id);

  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(true);

  const [mainData, setMainData] = useState(null);
  const [detailData, setDetailData] = useState(null);
  const [cellDetailsLoading, setCellDetailsLoading] = useState(false);
  const [cellDetails, setCellDetails] = useState(null);
  const [defaultDisplayBlock, setDefaultDisplayBlock] = useState(null);

  const loadMainData = () => {
    setErr(null);
    setLoading(true);
    const folder = getPropertyFileName(target.id);
    const filename = target.project;
    fetch(`https://realsmart.global.ssl.fastly.net/d/p/${folder}/${filename}`)
    .then(resp => {
      return resp.text();
    }).then(txt => {
      const data = decompressBasicPropertyData(txt);
      setMainData(data);
      setLoading(false);
    }).catch(err => {
      setErr('Failed to load property');
      setLoading(false);
    });
  };

  const loadDetailData = () => {
    setErr(null);
    setLoading(true);
    const folder = getPropertyFileName(target.id);
    const filename = target.project;
    fetch(`https://realsmart.global.ssl.fastly.net/d/d/${folder}/${filename}`)
    .then(resp => {
      return resp.text();
    }).then(txt => {
      const data = decompressAdvPropertyData(mainData.streets, txt);
      setDetailData(data);
      setLoading(false);
    }).catch(err => {
      setErr('Failed to load property');
      setLoading(false);
    });
  };

  useEffect(() => {
    continueIfAllowedUser(user, () => {
      setMainData(null);
      setDetailData(null);
      setSelectedTab('overview');
      onSwitchTab('overview');
      setTimeout(() => {
        if (target?.id) {
          loadMainData();
        }
      }, 288);
    }, navigate, pathname);
  }, [user, target]);

  const onSwitchTab = (tab) => {
    if (TABS_REQUIRE_ADV_DATA.has(tab) && !detailData) {
      loadDetailData();
    }
    document.getElementById('project-details-modal')?.scrollTo({ top: 0 });
  };

  const closeSideBar = () => {
    if (document.getElementById('listingDetails').classList.contains('show')) {
      document.getElementById('details-close-button').click();
    }
  };

  const mediaMatches = checkMediaQuery();

  if (!marker) {
    marker = setMarkerData(target.id);
  }

  return (
    <>
      {!isMinimized && err &&
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed modal-container-expand" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-between text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
              <div
                className="p-2 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close py-5" />
              </div>
            </div>
          }

          {!mediaMatches
            && <div className="d-flex justify-content-end text-10 text-light-1">
              <div
                className="p-2 px-10 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close" />
              </div>
            </div>
          }

          <div className="modal-body watermark d-flex">
            {!mediaMatches
              && <div
                className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                  <i className="icon-chevron-sm-down px-5" />
                </div>
              </div>
            }

            <div
              id="project-details-modal"
              className={`p-2 modal-scoll-content text-center fw-500 py-40 ${
                isMaximized ? 'modal-scroll-media-full' : (mediaMatches ? 'modal-scroll-media' : '')
              }`}
            >
              Property not found
            </div>
          </div>
        </div>
      }

      {!isMinimized && !err && (
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-between text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
              <div
                className="p-2 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close py-5" />
              </div>
            </div>
          }

          {!mediaMatches
            && <div className="d-flex justify-content-end text-10 text-light-1">
              <div
                className="p-2 px-10 pt-5 pb-0 cursor-pointer"
                onClick={() => closePropertyDetails()}
              >
                <i className="icon-close" />
              </div>
            </div>
          }

          <TabButtons
            tabs={TABS}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            onSwitchTab={onSwitchTab}
            session={session}
            projectName={target.id}
          />

          <div className="modal-body watermark d-flex">
            {!mediaMatches
              && <div
                className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                  <i className="icon-chevron-sm-down px-5" />
                </div>
              </div>
            }

            <div
              id="project-details-modal"
              className={`p-2 modal-scoll-content ${
                isMaximized ? 'modal-scroll-media-full' : (mediaMatches ? 'modal-scroll-media' : '')
              }`}
            >

              <ScrollToTop />

              {loading
                && <div className="loader-container">
                  <Loader />
                </div>
              }

              {/* detail page tabs */}
              {mainData && marker && selectedTab === 'overview'
                && <OverviewTab
                  user={user} data={mainData} marker={marker} isMaximized={isMaximized} screenDim={screenDim}
                  compareList={compareList} setCompareList={setCompareList} addToCompareList={addToCompareList}
                  goToProperty={goToProperty}
                />
              }
              {detailData && selectedTab === 'transactions'
                && <TransactionsTab user={user} data={detailData} isMaximized={isMaximized} screenDim={screenDim}
                  goToUnit={(unit) => setCellDetails({ ...unit, showButton: true })}
                />
              }
              {detailData && selectedTab === 'rental'
                && <RentalTab user={user} data={detailData} isMaximized={isMaximized} screenDim={screenDim} />
              }

              {detailData && selectedTab === 'units'
                && <UnitsTab
                  user={user} data={detailData} isMaximized={isMaximized} screenDim={screenDim}
                  setCellDetails={setCellDetails} setCellDetailsLoading={(setCellDetailsLoading)}
                  defaultBlock={defaultDisplayBlock}
                />
              }

              {detailData && selectedTab === 'nearby'
                && <NearbyTab
                  user={user} data={detailData} mapData={mapData} basicData={mainData}
                  isMaximized={isMaximized} goToProperty={goToProperty} focus={focus} setFocus={setFocus}
                />
              }

              {mainData && selectedTab === 'compare'
                && <CompareTab
                  user={user} data={mainData} isMaximized={isMaximized} goToProperty={goToProperty} screenDim={screenDim}
                  compareList={compareList} setCompareList={setCompareList} setFocusOn={setFocusOn} viewComparePro={viewComparePro}
                />
              }
              

              {/* <>
                {selectedTab === 'compare' && loadedTableData
                  && <CompareTab
                    id={id}
                    user={user}
                    compsResults={compsResults}
                    screenDim={screenDim}
                    isMaximized={isMaximized}
                  />
                }
                {selectedTab === 'nearby' && loadedMainData
                  && <NearbyTab
                    user={user}
                    id={id}
                    schools={schools}
                    resultAllLocations={resultAllLocations}
                    nearbyProperties={nearbyProperties}
                    comparable={comparable}
                    onClickPlace={onClickPlace}
                    highlightList={highlightList}
                    clearHighlightList={() => setHighlightList([])}
                    isMaximized={isMaximized}
                    blockMarker={blockMarker}
                    onBlockSelect={onBlockSelect}
                    clearBlockSelect={() => setBlockMarker(null)}
                    onSchoolSelect={onSchoolSelect}
                    goToProperty={goToPropertySelect}
                  />
                }
                {selectedTab === 'discuss'
                  && <DiscussionTab
                    user={user}
                    id={id}
                  />
                }
                {TABS_REQUIRE_TABLE_DATA.has(selectedTab) && !loadedTableData
                  && <div className="loader-container">
                    <Loader />
                  </div>
                }
                {TABS_REQUIRE_MAIN_DATA.has(selectedTab) && !loadedMainData
                  && <div className="loader-container">
                    <Loader />
                  </div>
                }
              </> */}

              <div
                className="offcanvas offcanvas-end filter-bar-nopad"
                tabIndex="-1"
                id="listingDetails"
              >
                <div className={`offcanvas-header block-list-panel ${isMaximized ? 'block-list-max' : ''}`}>
                  <h5 className="offcanvas-title text-16" id="offcanvasLabel">
                    {cellDetails?.displayBlock ?? ''} {cellDetails ? (
                      cellDetails.unit !== NA ? cellDetails.unit : ''
                    ) : ''}
                  </h5>
                  <button
                    id="details-close-button"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>

                {cellDetails?.showButton
                  && <button
                    className="button -dark-1 bg-blue-1 text-white h-30 px-5 rounded-100 text-12 ml-10 mr-10 mt-5 mb-5"
                    onClick={() => {
                      setDefaultDisplayBlock(cellDetails.displayBlock);
                      setSelectedTab('units');
                      onSwitchTab('units');
                      closeSideBar();
                    }}
                  >
                    Go to Stack View
                  </button>
                }

                <div className="offcanvas-body watermark">
                  <aside className="sidebar y-gap-20 xl:d-block pl-10 pb-20">
                    {cellDetailsLoading
                      && <div className="loader-container">
                        <Loader />
                      </div>
                    }
                    {!cellDetailsLoading && cellDetails
                      && <>
                        <h2 className="text-15 lh-1 fw-500">Price Trend ($)</h2>
                        <AnnotatedChart
                          label="Price Trend ($)"
                          data={
                            cellDetails.tx.map(row => ({
                              x: row['ts'],
                              y: row.price,
                            }))
                          }
                          tooltipTitle="Price ($)"
                          tooltipLabelCallback={
                            (context) => {
                              const date = new Date(context.raw.x);
                              const formattedDate = formatShortDate(date);
                              return `${formattedDate}: $${context.raw.y.toLocaleString()}`;
                            }
                          }
                          yAxisLabel="Price ($)"
                        />

                        <div className="pt-10"></div>

                        {cellDetails.gains.filter(g => g !== null).length > 0 && <>
                          <h2 className="text-15 lh-1 fw-500">Annualized Profit (%)</h2>
                          <AnnotatedChart
                            label="Annualized Profit (%)"
                            data={
                              cellDetails.gains.filter(g => g !== null).map(row => ({
                                x: row['ts'],
                                y: row['gain'],
                              }))
                            }
                            tooltipTitle="Annualized Profit (%)"
                            tooltipLabelCallback={
                              (context) => {
                                const date = new Date(context.raw.x);
                                const formattedDate = formatShortDate(date);
                                return `${formattedDate}: ${context.raw.y.toFixed(2)}%`;
                              }
                            }
                            yAxisLabel="Annualized Profit (%)"
                          />
                        </>}

                        {cellDetails.tx.length > 0 
                          && <h2 className="text-15 lh-1 fw-500">Transaction History</h2>
                        }
                        
                        {cellDetails.tx.map((row, i) => (
                          <div
                            className="px-10 py-5 bg-blue-2 text-12 text-blue-1 mr-20 mt-10 text-10"
                          >
                            <span className="text-14 fw-500">
                              {convertDisplayDateMsec(row['ts'])}
                            </span>
                            <br />
                            <span className="">
                              Type of Sale: {row.type}
                            </span>
                            <br />
                            <span className="">
                              Price: ${row.price.toLocaleString()}
                            </span>
                            <br />
                            <span className="">
                              Price per area: ${row.unitPrice.toLocaleString()} psf
                            </span>
                            {cellDetails.gains.length > 0 && cellDetails.gains[i] !== null
                              && <>
                                <br />
                                <span className="">
                                  Annualized Profit (%): {cellDetails.gains[i].gain.toFixed(2)}%
                                </span>
                              </>
                            }
                          </div>
                        ))}
                      </>
                      }
                    </aside>
                  </div>
              </div>

            </div>
          </div>

          <div className="footer-block" />

        </div>
      )}
    </>
  );
};

export default PropertyView;
