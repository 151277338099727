const PropertyButtons = ({
  options,
  selection,
  onSelect
}) => {
  return (
    <div className="d-flex flex-wrap justify-content-start py-0 pt-10 noselect border-top-light">
      {options.map(option =>
        <button
          key={option.id}
          className={`p-2 button mr-10 mb-5 ${
            !selection?.[option.id]
              ? '-dark-1 bg-blue-1 text-white'
              : '-blue-1 bg-blue-1-05 text-blue-1'
          } h-30 px-10 rounded-100 text-12`}
          onClick={() => onSelect(option.id)}
        >
          <i className={`${option.icon} mr-5`} />
          {
            !selection?.[option.id] ? option.label : (option.altLabel ?? option.label)
          }
        </button>
      )
    }
    </div>
  );
};

export default PropertyButtons;
